<template>
    <div>
        <div v-if="visible" class="modal" role="dialog" style="display: block;">
            <div ref="modal" class="modal-dialog linnworks-product-list-modal" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 id="exampleModalLabel" class="modal-title">
                            Specify products for invoice
                        </h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                                @click="$emit('close')"
                        >
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <p>
                            This shipment will get a printed invoice along with shipping labels. Please specify the
                            product groups you want to show there.
                        </p>
                        <div class="form-group m-form__group row">
                            <label class="col-3 col-form-label">Group Name</label>
                            <label class="col-3 col-form-label">Channel SKU</label>
                            <label class="col-2 col-form-label">Quantity</label>
                            <label class="col-2 col-form-label">Price Per Item</label>
                        </div>

                        <div v-for="item in overrideItems"
                             class="form-group m-form__group row linnworks-product-list-modal__item-row"
                        >
                            <div class="col-3 linnworks-product-list-modal__item-col">
                                <input v-model="item.itemName" type="text" :class="['form-control', !isValidText(item.itemName) ? 'linnworks-product-list-modal__input--has-error' : '']">
                            </div>
                            <div class="col-3 linnworks-product-list-modal__item-col">
                                <input v-model="item.channelSku" type="text" :class="['form-control', !isValidText(item.channelSku) ? 'linnworks-product-list-modal__input--has-error' : '']">
                            </div>
                            <div class="col-2 linnworks-product-list-modal__item-col">
                                <input v-model="item.quantity" type="text" :class="['form-control', !isValidNumber(item.quantity) ? 'linnworks-product-list-modal__input--has-error' : '']">
                            </div>
                            <div class="col-2 linnworks-product-list-modal__item-col">
                                <input v-model="item.itemPrice" type="text" :class="['form-control', !isValidNumber(item.itemPrice) ? 'linnworks-product-list-modal__input--has-error' : '']">
                            </div>
                            <div v-if="overrideItems.length > 1" class="col-1 linnworks-product-list-modal__item-col">
                                <button class="btn btn-danger" @click="remove(item)">
                                    <i class="fa fa-trash-o" />
                                </button>
                            </div>
                        </div>
                        <div class="form-group m-form__group row">
                            <div class="col">
                                <button class="btn btn-success" @click="addItem()">
                                    <i class="fa fa-plus" /> Add item
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal" @click="$emit('close')">
                            Close
                        </button>
                        <button type="button" :class="['btn', 'btn-primary', !isValid ? 'disabled' : '']" :disabled="!isValid" @click="confirm()">
                            Print
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <modal-confirm
            slot="modal"
            text="The number of items for invoice is not consistent with the items scanned. Are you sure you want to continue?"
            title="Not all items added for invoice"
            confirm-button-text="Confirm"
            :visible="inconsistentQtyConfirmVisible"
            :loading="false"
            :disable-enter-confirm="true"
            @confirmed="$emit('confirmed')"
            @close="inconsistentQtyConfirmVisible = false"
        />
    </div>
</template>

<style scoped>
    .modal {
        background: rgba(0, 0, 0, 0.3);
    }

    .linnworks-product-list-modal {
        max-width: 700px;
    }

    .linnworks-product-list-modal__item-col {
        margin-bottom: 5px;
    }
    .linnworks-product-list-modal__input--has-error {
        border-color: red;
    }
</style>

<script>
import ModalConfirm from '@/components/Modals/ModalConfirm.vue'

export default {
    components: {
        ModalConfirm,
    },
    props: {
        visible: {
            type: Boolean,
            default: false,
        },
        defaultProductName: {
            type: String,
            default: '',
        },
        defaultProductChannelSku: {
            type: String,
            default: '',
        },
        defaultProductPrice: {
            type: Number,
            default: 0,
        },
        totalProductCount: {
            type: Number,
            default: 0,
        },
        overrideItems: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            inconsistentQtyConfirmVisible: false,
        }
    },
    computed: {
        isValid() {
            for (const item of this.overrideItems) {
                if (!this.isValidText(item.itemName)) {
                    return false
                }
                if (!this.isValidText(item.channelSku)) {
                    return false
                }
                if (!this.isValidNumber(item.itemPrice)) {
                    return false
                }
                if (!this.isValidNumber(item.quantity)) {
                    return false
                }
            }

            return true
        },
    },
    watch: {
        visible() {
            this.addInitialItem()
        },
    },
    created() {
        if (!this.disableEnterConfirm) {
            window.addEventListener('keyup', this.handleKeyEvent)
        }
        this.addInitialItem()
    },
    methods: {
        handleKeyEvent(event) {
            if (this.visible) {
                if (event.keyCode === 27) {
                    this.$emit('close')
                }
                if (event.keyCode === 13) {
                    this.$emit('confirmed')
                }
            }
        },
        addInitialItem() {
            if (this.overrideItems.length === 0) {
                this.overrideItems.push({
                    itemName: this.defaultProductName,
                    channelSku: this.defaultProductChannelSku,
                    itemPrice: this.defaultProductPrice,
                    quantity: this.totalProductCount,
                })
            }
        },
        remove(item) {
            const index = this.overrideItems.indexOf(item)
            this.overrideItems.splice(index, 1)
        },
        getItemsLeft() {
            const itemsAdded = this.overrideItems.reduce((cary, item) => cary + parseInt(item.quantity), 0)
            let itemsLeft = parseInt(this.totalProductCount) - itemsAdded
            if (itemsLeft < 0) {
                itemsLeft = 0
            }
            return itemsLeft
        },
        addItem() {
            this.overrideItems.push({
                itemName: '',
                channelSku: '',
                itemPrice: 0,
                quantity: this.getItemsLeft(),
            })
        },
        confirm() {
            this.inconsistentQtyConfirmVisible = false
            if (this.getItemsLeft() !== 0) {
                this.inconsistentQtyConfirmVisible = true
            } else {
                this.$emit('confirmed')
            }
        },
        isValidText(text) {
            return text.length !== 0
        },
        isValidNumber(number) {
            number = parseFloat(number)
            // eslint-disable-next-line no-restricted-globals
            if (isNaN(number)) {
                return false
            }
            return number !== 0
        },
    },
}
</script>
