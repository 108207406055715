<template>
    <div>
        <div
            v-if="typeof primaryAction === 'function'"
        >
            <b-button-group>
                <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    :disabled="isDisabled()"
                    size="sm"
                    @click="callPrimaryAction"
                >
                    {{ loading ? 'Loading...' : primaryText }}
                </b-button>
                <b-dropdown
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    :disabled="isDisabled()"
                    size="sm"
                    no-caret
                >
                    <template #button-content>
                        <feather-icon
                            icon="ChevronDownIcon"
                        />
                    </template>
                    <b-dropdown-item
                        v-for="(button, index) in buttons"
                        :key="index"
                        @click="callAction(button.action)"
                    >
                        {{ button.title }}
                    </b-dropdown-item>
                </b-dropdown>
            </b-button-group>

        </div>
        <div 
            v-else
        >
            <b-dropdown
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                :text="loading ? 'Loading...' : primaryText"
                :disabled="isDisabled()"
                size="sm"
                :no-caret="primaryAction !== null"
            >
                <b-dropdown-item
                    v-for="(button, index) in buttons"
                    :key="index"
                    @click="callAction(button.action)"
                >
                    {{ button.title }}
                </b-dropdown-item>
            </b-dropdown>
            <b-button
                v-if="primaryAction !== null"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="btn-icon"
                size="sm"
                :disabled="isDisabled()"
                @click="callPrimaryAction"
            >
                <feather-icon icon="ChevronDownIcon" />
            </b-button>
        </div>
    </div>
</template>

<style>
</style>
<script>
import { mixin as Clickaway } from 'vue-clickaway'
import {
    BDropdown,
    BDropdownItem,
    BButton,
    BButtonGroup,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
    components: {
        BDropdown,
        BDropdownItem,
        BButton,
        BButtonGroup,
    },
    directives: {
        Ripple,
    },
    mixins: [
        Clickaway,
    ],
    props: {
        buttons: {
            type: Array,
            default: () => [],
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        loading: {
            type: Boolean,
            default: false,
        },
        primaryText: {
            type: String,
            required: true,
        },
        primaryAction: {
            type: Function,
            default: null,
        },
        extraClasses: {
            type: Array,
            default: () => [],
        },
        forceDisplayDropdown: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            isOpen: false,
        }
    },
    computed: {
        buttonClasses() {
            const classes = this.extraClasses.slice(0) // clone the array
            classes.push('btn-sm')
            classes.push('btn')
            classes.push('btn-primary')
            if (this.isDisabled()) {
                classes.push('disabled')
            }

            return classes
        },
    },
    watch: {
        isOpen() {
            if (this.isOpen === false) {
                window.removeEventListener('keyup', this.onEscapeKeyUp)
            } else {
                window.addEventListener('keyup', this.onEscapeKeyUp)
            }
        },
    },
    methods: {
        toggleDropdown() {
            if (this.disabled) {
                return
            }
            this.isOpen = !this.isOpen
        },
        closeDropdown() {
            this.isOpen = false
        },
        callAction(action) {
            this.isOpen = false
            action()
        },
        isDisabled() {
            return this.disabled || this.loading
        },
        callPrimaryAction() {
            if (typeof this.primaryAction === 'function') {
                this.primaryAction()
                return
            }

            this.toggleDropdown()
        },
        onEscapeKeyUp(event) {
            if (event.which === 27) {
                this.toggleDropdown()
            }
        },
    },
}
</script>
