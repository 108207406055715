<template>
    <div v-if="scannedItem" class="m-portlet m-form">
        <div class="m-portlet__body item-scanner__portlet-body">
            <div class="row item-scanner__item-info-row">
                <div class="col-12">
                    <span class="item-scanner__scanned-item-title">
                        {{ scannedItem.title }}
                    </span>
                </div>
            </div>
            <div class="row item-scanner__item-info-row">
                <div class="col-6">
                    <div class="row">
                        <div class="col-12">
                            <span class="item-scanner__item-misc-info">
                                FNSKU:
                                <b-badge
                                    variant="success"
                                >
                                    <strong>{{ scannedItem.fn_sku }}</strong>
                                </b-badge>

                            </span>
                        </div>
                        <div class="col-12">
                            <span class="item-scanner__item-misc-info">
                                EAN:
                                <strong>{{ scannedItem.external_id }}</strong>
                            </span>
                        </div>
                        <div class="col-12">
                            <span class="item-scanner__item-misc-info">
                                SKU:
                                <strong>{{ scannedItem.merchant_sku }}</strong>
                            </span>
                        </div>
                        <div class="col-12">
                            <span class="item-scanner__item-misc-info">
                                Box:
                                <strong>{{ scannedItem.box }}</strong>
                            </span>
                        </div>
                    </div>
                </div>

                <div class="col-6">
                    <b-badge
                        :variant="getItemCountColor()"
                        class="m-badge-xxxl"
                    >
                        {{ scannedItem.listed }}
                    </b-badge>
                    <span class="item-scanner__number-or">of</span>
                    <b-badge
                        variant="dark"
                        class="m-badge-xxl"
                    >
                        {{ scannedItem.shipped }}
                    </b-badge>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import {
    BBadge,
} from 'bootstrap-vue'

export default {
    components: {
        BBadge,
    },
    props: {
        scannedItem: {
            type: Object,
            default: () => ({}),
        },
    },
    data() {
        return {}
    },
    methods: {
        getItemCountColor() {
            if (parseFloat(this.scannedItem.listed) === parseFloat(this.scannedItem.shipped)) {
                return 'success'
            }
            if (parseFloat(this.scannedItem.listed) > parseFloat(this.scannedItem.shipped)) {
                return 'warning'
            }

            return 'danger'
        },
    },

}
</script>

<style>

    .item-scanner__portlet-body {
        padding-top: 5px !important;
        padding-bottom: 15px !important;
    }

    .item-scanner__item-info-row {
        margin-bottom: 10px;
    }

    .m-badge-xxxl {
        font-size: 120px;
        border-radius: 25px !important;
        line-height: 120px;
        min-width: 120px;
        min-height: 120px;
    }

    .m-badge-xxl {
        font-size: 90px;
        border-radius: 15px !important;
        line-height: 90px;
        min-width: 90px;
        min-height: 90px;
    }

    .item-scanner__scanned-item-title {
        font-size: 25px;
    }

    .item-scanner__portlet-body {
        padding-top: 20px !important;
    }

    .item-scanner__item-misc-info {
        font-size: 25px;
    }

    .item-scanner__number-or {
        font-size: 26px;
        padding: 0px 20px;
    }

    .item-scanner__item-misc-badge {
        font-size: 25px;
        line-height: 46px;
        padding: 0 20px !important;
    }
</style>
