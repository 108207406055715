<template>
    <div>
        <div
            class="d-flex align-items-center"
        >
            <h3
                class="mr-2"
            >
                Shipment {{ shipmentData.shipment_id }}
            </h3>
            <div
                v-if="!shipmentDataLoading"
                class="shipment-info"
            >
                <div
                    :class="isDark ? 'shipment-info__item-dark' : 'shipment-info__item-light'"
                >
                    <span>Created by: </span>
                    <strong>{{ shipmentData.creator }}</strong>
                </div>
                <div
                    :class="isDark ? 'shipment-info__item-dark' : 'shipment-info__item-light'"
                >
                    <span>Boxes: </span>
                    <strong>{{ shipmentData.boxes.length }}</strong>
                </div>
                <div
                    :class="isDark ? 'shipment-info__item-dark' : 'shipment-info__item-light'"
                >
                    <span>Status: </span>
                    <strong
                        v-if="shipmentData.status === 1"
                    >
                        <b-badge
                            variant="success"
                        >
                            Completed
                        </b-badge>
                    </strong>
                    <strong
                        v-if="shipmentData.status === 0"
                    >
                        <b-badge
                            variant="danger"
                        >
                            In progress
                        </b-badge>
                    </strong>
                </div>

                <div
                    :class="isDark ? 'shipment-info__item-dark' : 'shipment-info__item-light'"
                >
                    <span>Items processed: </span>
                    <b-badge
                        :variant="itemsScanned === totalItems ? 'success' : 'danger'"
                    >
                        <template
                            v-if="itemsLoading"
                        >
                            <feather-icon
                                icon="LoaderIcon"
                                class="spinner"
                            />
                        </template>
                        <template v-else>
                            {{ itemsScanned }}
                        </template>
                    </b-badge>
                    of
                    {{ parseInt(totalItems) }}
                </div>
                <b-dropdown
                    variant="link"
                    toggle-class="text-decoration-none"
                    no-caret
                >
                    <template v-slot:button-content>
                        <feather-icon
                            icon="DownloadIcon"
                            size="16"
                            class="text-body align-middle mr-25"
                        />
                    </template>
                    
                    <b-dropdown-item
                        @click="triggerDownload(exportBoxXlsx)"
                    >
                        {{ exportBoxXlsxLoading ? 'Loading...' : 'Export Box XLSX' }}
                    </b-dropdown-item>
                    <b-dropdown-item
                        @click="triggerDownload(exportBoxCsv)"
                    >
                        {{ exportBoxCsvLoading ? 'Loading...' : 'Export Box CSV' }}
                    </b-dropdown-item>
                    <b-dropdown-item
                        @click="triggerDownload(exportCsv)"
                    >
                        {{ exportCsvLoading ? 'Loading...' : 'Export to CSV' }}
                    </b-dropdown-item>
                </b-dropdown>
            </div>
        </div>

        <b-overlay
            :bg-color="isDark ? '#283046' : '#f8f8f8'"
            :show="shipmentDataLoading"
            rounded="lg"
            spinner-variant="primary"
        >
            <b-container
                fluid
                class="mt-2"
            >
                <b-row>
                    <b-col
                        cols="6"
                    >
                        <b-card
                            id="shipment-action-tabs"
                            ref="shipment-action-tabs"
                        >
                            <b-tabs>
                                <b-tab
                                    title="Add / remove items"
                                >
                                    <item-scanner
                                        :boxes="shipmentData.boxes"
                                        :shipment-id="parseInt($route.params.id)"
                                        :initial-loading="shipmentDataLoading"
                                        :units="units"
                                        @item-scanned="itemScanned"
                                        @box-deleted="loadFullData"
                                        @show-item-image="showItemInfo"
                                    />
                                </b-tab>
                                <b-tab
                                    v-if="showLinnworksIntegrationStuff()"
                                    title="Linnworks sync"
                                >
                                    <linnworks-order-data
                                        v-if="showLinnworksIntegrationStuff()"
                                        :total-items="parseInt(totalItems)"
                                        :shipment-id="shipmentId"
                                        :address="shipmentData.address"
                                        :visible="!itemsLoading"
                                    />
                                </b-tab>
                            </b-tabs>
                        </b-card>
                    </b-col>
                    <b-col
                        cols="6"
                    >
                        <b-card
                            v-if="scannedItem && scannedItem.image"
                        >
                            <div class="item-scanner__col">
                                <div class="scanned-item-image__wrapper item-scanner__equal-height-box" :style="'height: ' + getHeightOfScanner() + 'px'">
                                    <img :src="scannedItem.image.image_url" class="scanned-item-image__image" alt="">
                                </div>
                            </div>
                        </b-card>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col
                        cols="12"
                    >
                        <b-card
                            v-if="scannedItem !== null"
                        >
                            <scanned-item-info
                                :scanned-item="scannedItem"
                            />
                        </b-card>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col
                        cols="12"
                    >
                        <b-card>
                            <items-table-filters-small :boxes="shipmentData.boxes" />
                            <items-table
                                :loading="itemsLoading"
                                :boxes="shipmentData.boxes"
                                :shipment-id="shipmentId"
                                @refresh="loadFullData()"
                            />
                        </b-card>
                    </b-col>
                </b-row>
            </b-container>
        </b-overlay>

        <modal-confirm-vuexy
            text="One or more products in your shipment have unit quantities that are different from quantities in Amazon Seller Central. Please review and if necessary modify your unit quantities accordingly in Amazon Seller Central under Prepare Shipment > Review shipment contents."
            title="Export warning"
            :ok-function="currentExportAction"
        />
    </div>
</template>

<style>
.m-portlet__head-tool-button {
    margin-left: 10px;
}
.shipment-info__item-light {
    display: inline-block;
    background: #fff;
    height: 30px;
    line-height: 30px;
    border-radius: 0.25rem;
    padding: 0 10px;
    margin-right: 10px;
    margin-bottom: 2px;
    -webkit-box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1);
    box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1);
}
.shipment-info__item-dark {
    display: inline-block;
    background: #283046;
    height: 30px;
    line-height: 30px;
    border-radius: 0.25rem;
    padding: 0 10px;
    margin-right: 10px;
    margin-bottom: 2px;
}
.shipment-info__item--no-bg {
    background: none;
    padding: 0;
}
.shipment-info_button-dropdown {
    position: absolute;
    padding: 10px;
    box-shadow: 0 1px 15px 1px rgba(69, 65, 78, 0.08);
    background-color: #fff;
    z-index: 1;
    right: 0;
}
.shipment-info_button-dropdown button {
    display: block;
    margin-bottom: 5px;
}
.shipment-info_button-dropdown button:last-child {
    margin-bottom: 0;
}
.shipment-info__item--has-dropdown {
    position: relative;
}

.shipment-action-tab {
    padding: 0!important;
}
.scanned-item-image__wrapper {
    text-align: center;
    background: #fff;
    padding: 10px;
}

.scanned-item-image__image {
    max-width: 100%;
    max-height: 100%;
}
.item-scanner__col {
    padding-bottom: 28px;
}
.item-scanner__equal-height-box {
    height: 100%;
}
</style>


<script>
import Axios from 'axios'
import ItemsTable from '@/components/Shipments/ItemsTable.vue'
import ItemScanner from '@/components/Shipments/ItemScanner.vue'
import ItemsTableFiltersSmall from '@/components/Shipments/ItemsTableFiltersSmall.vue'
import LinnworksOrderData from '@/components/Shipments/LinnworksOrderData.vue'
import { mixin as clickaway } from 'vue-clickaway'
import Store from '@/store/index'
import {
    LocalStorageShipmentItemFilterKeys, ShipmentFilterOptions, Units, FeatureLinnworksIntegration,
} from '@/constants'
import ScannedItemInfo from '@/components/Shipments/ScannedItemInfo.vue'
import Features from '@/services/Features'
import Access from '@/services/Access'
import ModalConfirmVuexy from '@/components/Modals/ModalConfirmVuexy'
import {
    BCard,
    BContainer,
    BRow,
    BCol,
    BBadge,
    BDropdown,
    BDropdownItem,
    BTab,
    BTabs,
    BOverlay,
} from 'bootstrap-vue'
import useAppConfig from '@core/app-config/useAppConfig'
import { computed } from '@vue/composition-api'

export default {
    name: 'shipments.index',
    components: {
        ScannedItemInfo,
        ItemsTable,
        ItemScanner,
        ItemsTableFiltersSmall,
        LinnworksOrderData,
        ModalConfirmVuexy,

        BCard,
        BContainer,
        BRow,
        BCol,
        BBadge,
        BDropdown,
        BDropdownItem,
        BTab,
        BTabs,
        BOverlay,
    },
    mixins: [
        clickaway,
    ],
    data() {
        return {
            shipmentData: {
                name: '',
                created_at: '',
                status: null,
                boxes: [],
                address: {
                    address1: '',
                    address2: '',
                    address3: '',
                    city: '',
                    region: '',
                    country: '',
                    postCode: '',
                },
                integrations: {
                    linnworks: false,
                },
            },
            shipmentDataLoading: true,
            itemsLoading: true,
            exportCsvLoading: false,
            exportBoxCsvLoading: false,
            exportBoxXlsxLoading: false,
            linnworksModalOpen: false,
            units: Units.METRIC,
            currentExportAction: () => {},
            scannedItem: null,
            currentActionTab: 'scan',
            unsubscribeFromCustomFieldUpdates: null,
            loadItemsTimeout: null,
        }
    },
    computed: {
        itemsScanned() {
            return this.items.reduce((count, item) => count + parseInt(item.listed), 0)
        },
        totalItems() {
            return this.items.reduce((count, item) => count + parseInt(item.shipped), 0)
        },
        items() {
            return Store.getters.shipmentItems
        },
        shipmentId() {
            return parseInt(this.$route.params.id)
        },
    },
    created() {
        this.$store.commit('shipment_items', [])
        // Note: the state change to load the data initially is triggered when the table layout is loaded.
        this.unsubscribeFromCustomFieldUpdates = this.$store.watch(
            (state, getters) => getters.shipmentCustomFields,
            newVal => {
                if (newVal) {
                    this.loadFullData()
                }
            },
        )
    },
    destroyed() {
        this.unsubscribeFromCustomFieldUpdates()
    },
    setup() {
        const { skin } = useAppConfig()
        const isDark = computed(() => skin.value === 'dark')

        return {
            isDark,
        }
    },
    methods: {
        loadFullData() {
            this.loadShipment()
            this.loadItems()
        },
        loadShipment() {
            Axios({
                url: `${process.env.VUE_APP_API_HTTP_ROOT}shipments/view`,
                data: {
                    id: this.shipmentId,
                },
                method: 'POST',
            })
                .then(resp => {
                    this.shipmentData = resp.data
                    this.shipmentDataLoading = false
                    this.setUnits()
                })
                .catch(() => {
                    this.shipmentDataLoading = false
                    this.shipmentData = {
                        name: '',
                    }
                })
        },
        loadItems() {
            this.itemsLoading = true
            this.setFilterValues()
            Axios({
                url: `${process.env.VUE_APP_API_HTTP_ROOT}shipments/items`,
                data: {
                    id: this.shipmentId,
                    fields: this.$store.getters.shipmentCustomFields,
                },
                method: 'POST',
            })
                .then(resp => {
                    this.itemsLoading = false
                    this.$store.commit('shipment_items', resp.data)
                    this.validateBoxFilter()
                })
                .catch(() => {
                    this.itemsLoading = false
                    this.$store.commit('shipment_items', [])
                })
        },
        setFilterValues() {
            let savedShipmentShowType = localStorage.getItem(LocalStorageShipmentItemFilterKeys.STATUS)
            if (!savedShipmentShowType) {
                savedShipmentShowType = Store.getters.ShipmentItemFilters.status
            }
            this.$store.commit('shipment_item_filters_status', savedShipmentShowType)

            let savedShipmentFnskuType = localStorage.getItem(LocalStorageShipmentItemFilterKeys.TYPE)
            if (!savedShipmentFnskuType) {
                savedShipmentFnskuType = Store.getters.ShipmentItemFilters.type
            }
            this.$store.commit('shipment_item_filters_type', savedShipmentFnskuType)

            let savedShipmentBox = localStorage.getItem(LocalStorageShipmentItemFilterKeys.BOX)
            if (!savedShipmentBox) {
                savedShipmentBox = Store.getters.ShipmentItemFilters.box
            }
            this.$store.commit('shipment_item_filters_box', savedShipmentBox)

            let savedShipmentSearch = localStorage.getItem(LocalStorageShipmentItemFilterKeys.SEARCH)
            if (!savedShipmentSearch) {
                savedShipmentSearch = Store.getters.ShipmentItemFilters.search
            }
            this.$store.commit('shipment_item_filters_search', savedShipmentSearch)
        },
        validateBoxFilter() {
            const savedShipmentBox = localStorage.getItem(LocalStorageShipmentItemFilterKeys.BOX)
            if (!this.shipmentData.boxes[savedShipmentBox]) {
                this.$store.commit('shipment_item_filters_box', '')
            }
        },
        exportCsv() {
            this.exportCsvLoading = true
            let data = '"No","Code","Ean","Name","Stock level","Binrack","Count"\n'

            let i = 1
            for (const item of this.items) {
                data += `"${i}"`
                data += `,"${this.escapeCsv(item.fn_sku)}"`
                data += `,"${this.escapeCsv(item.external_id)}"`
                data += `,"${this.escapeCsv(item.title)}"`
                data += ',""'
                data += ',""'
                data += `,"${parseInt(item.listed)} of ${parseInt(item.shipped)}"\n`
                i += 1
            }

            const filename = `${this.getDate()}_${Math.ceil(Math.random() * 10000)}_${this.shipmentId}.csv`
            this.saveBlob(filename, data, 'text/csv;charset=utf-8;')
            this.exportCsvLoading = false
        },
        exportBoxCsv() {
            this.exportBoxCsvLoading = true
            Axios({
                url: `${process.env.VUE_APP_API_HTTP_ROOT}shipments/export-box-csv`,
                data: { id: this.shipmentId },
                method: 'POST',
            })
                .then(resp => {
                    const { boxes } = resp.data
                    const headerBoxColumns = []
                    let data = '"Title","FNSKU","External ID"'
                    for (const property in boxes) {
                        // eslint-disable-next-line no-prototype-builtins
                        if (boxes.hasOwnProperty(property)) {
                            const box = boxes[property]
                            data += `,"${this.escapeCsv(box.title)} - QTY"`
                            headerBoxColumns.push(property)
                        }
                    }
                    data += '\n'

                    for (const item of resp.data.items) {
                        data += `"${this.escapeCsv(item.title)}"`
                        data += `,"${this.escapeCsv(item.fn_sku)}"`
                        data += `,"${this.escapeCsv(item.external_id)}"`
                        for (const boxItem of headerBoxColumns) {
                            if (item.boxes[boxItem]) {
                                data += `,"${parseInt(item.boxes[boxItem])}"`
                            } else {
                                data += ',"0"'
                            }
                        }
                        data += '\n'
                    }
                    const filename = `${this.getDate()}_${Math.ceil(Math.random() * 10000)}_${this.shipmentId}.csv`
                    this.saveBlob(filename, data, 'text/csv;charset=utf-8;')

                    this.exportBoxCsvLoading = false
                })
                .catch(() => {
                    this.exportBoxCsvLoading = false
                })
        },
        exportBoxXlsx() {
            this.exportBoxXlsxLoading = true
            Axios({
                url: `${process.env.VUE_APP_API_HTTP_ROOT}shipments/export-box-xlsx`,
                data: { id: this.shipmentId },
                method: 'POST',
            })
                .then(resp => {
                    const data = this.s2ab(atob(resp.data.file))
                    const filename = `${this.getDate()}_${Math.ceil(Math.random() * 10000)}_${this.shipmentId}.xlsx`
                    this.saveBlob(filename, data, '')

                    this.exportBoxXlsxLoading = false
                })
                .catch(() => {
                    this.exportBoxXlsxLoading = false
                })
        },
        escapeCsv(str) {
            return str.replace(/"/g, '""')
        },
        saveBlob(filename, data, type) {
            const blob = new Blob([data], { type })
            const link = document.createElement('a')
            // Browsers that support HTML5 download attribute
            const url = URL.createObjectURL(blob)
            link.setAttribute('href', url)
            link.setAttribute('download', filename)
            link.style.visibility = 'hidden'
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
        },
        s2ab(s) {
            const buf = new ArrayBuffer(s.length)
            const view = new Uint8Array(buf)
            // eslint-disable-next-line no-bitwise
            for (let i = 0; i !== s.length; i += 1) view[i] = s.charCodeAt(i) & 0xFF
            return buf
        },
        getDate() {
            const today = new Date()
            let dd = today.getDate()
            let mm = today.getMonth() + 1 // January is 0!

            const yy = today.getFullYear()
            if (dd < 10) {
                dd = `0${dd}`
            }
            if (mm < 10) {
                mm = `0${mm}`
            }

            return `${dd}${mm}${yy}`
        },
        clearFilters() {
            this.$store.commit('shipment_item_filters_status', ShipmentFilterOptions.STATUS.ALL)
            this.$store.commit('shipment_item_filters_type', ShipmentFilterOptions.TYPE.ALL)
            this.$store.commit('shipment_item_filters_box', '')
            this.$store.commit('shipment_item_filters_search', '')
        },
        openLinnworksModal() {
            this.linnworksModalOpen = true
        },
        triggerDownload(exportFunction) {
            if (this.hasUncompletedItems()) {
                this.currentExportAction = exportFunction
                this.$bvModal.show('confirmModal')
            } else {
                exportFunction()
            }
        },
        hasUncompletedItems() {
            const items = Store.getters.shipmentItems
            for (const item of items) {
                if (parseInt(item.listed) !== parseInt(item.shipped)) {
                    return true
                }
            }
            return false
        },
        setUnits() {
            if (this.shipmentData.country.toLowerCase() === 'us') {
                this.units = Units.IMPERIAL
            }
        },
        showItemInfo(scannedItem) {
            this.scannedItem = scannedItem
        },
        getHeightOfScanner() {
            return this.$refs['shipment-action-tabs'].clientHeight - 70
        },
        showLinnworksIntegrationStuff() {
            return Features.checkFeature(FeatureLinnworksIntegration)
                && this.shipmentData.integrations.linnworks.is_enabled
                && Access.checkAccess('linnworks_integration.basic_integration', Access.LEVEL_READ)
        },
        itemScanned(code, qty, mode) {
            const items = this.items.map(item => {
                if (item.external_id === code || item.fn_sku === code) {
                    if (mode === 'add') {
                        item.listed += qty
                    } else if (mode === 'remove') {
                        item.listed -= qty
                    }
                }

                return item
            })
            this.$store.commit('shipment_items', items)
            clearTimeout(this.loadItemsTimeout)
            this.loadItemsTimeout = setTimeout(() => {
                this.loadFullData()
            }, 5000)
        }
    },
}
</script>
