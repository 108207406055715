import Store from '@/store'

export default class ShipmentItemService {
    static getItemByScanCode(code) {
        const items = Store.getters.shipmentItems

        for (const item of items) {
            if (item.fn_sku === code || item.external_id === code) {
                return item
            }
        }

        return null
    }

    static getItemBulkScanDefaultQuantity(code) {
        const item = this.getItemByScanCode(code)
        if (!item) {
            return null
        }

        if (!item.allowed_bulk_scan) {
            return false
        }

        return parseInt(item.allowed_bulk_scan.default_quantity)
    }
}
