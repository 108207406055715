<template>
    <div>

        <b-modal
            id="dynamicTableConfig"
            ref="dynamicTableConfig"
            title="Table configuration"
            size="lg"
            ok-title="Close"
            ok-variant="dark"
            ok-only
            @ok="$emit('close')"
            @close="$emit('close')"
            @hidden="$emit('close')"
        >
            <b-container
                v-if="!addingLayoutVisible"
                fluid
            >
                <b-row>
                    <b-col
                        cols="3"
                        align-self="center"
                    >
                        <label
                            class="col-form-label"
                        >
                            Selected layout:
                        </label>
                    </b-col>
                    <b-col
                        cols="7"
                    >
                        <select
                            v-model="currentLayoutId"
                            class="form-control"
                        >
                            <option
                                v-for="layout of layouts"
                                :key="layout.id"
                                :value="layout.id"
                            >
                                {{ layout.name }}
                            </option>
                        </select>
                    </b-col>
                    <b-col
                        cols="2"
                        align-self="end"
                    >
                        <b-button-group>
                            <b-button
                                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                variant="success"
                                class="btn-icon"
                                @click="startAddingLayout"
                            >
                                <feather-icon
                                    icon="PlusIcon"
                                />
                            </b-button>
                            <b-button
                                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                variant="danger"
                                class="btn-icon"
                                @click="deleteLayoutModalVisible = true"
                            >
                                <feather-icon
                                    icon="TrashIcon"
                                />
                            </b-button>
                        </b-button-group>
                    </b-col>
                </b-row>
                <b-row
                    class="mt-1"
                >
                    <b-col
                        cols="3"
                        align-self="center"
                    >
                        <label
                            for="layout-name-label"
                            class="col-form-label"
                        >
                            Layout name:
                        </label>
                    </b-col>
                    <b-col
                        cols="9"
                    >
                        <b-form-input
                            id="layout-name-label"
                            v-model="currentLayout.name"
                            placeholder="Layout name"
                            :disabled="currentLayoutId === 0"
                        />
                    </b-col>
                </b-row>
                <b-row class="mt-1">
                    <b-col cols="3">
                        Is public:
                    </b-col>
                    <b-col
                        cols="1"
                    >
                        <b-form-checkbox
                            v-model="currentLayout.isPublic"
                            :disabled="currentLayoutId === 0"
                        />
                    </b-col>
                </b-row>
                <template v-if="currentLayoutId !== 0">
                    <h3
                        class="mt-2"
                    >
                        Column configuration
                    </h3>
                    <hr>
                    <template
                        v-if="!layoutEditingDisabled"
                    >
                        <draggable
                            v-model="fields"
                            ghost-class="ghost"
                            handle=".dynamic-table-config__column-list-item-sort-handle"
                        >
                            <template
                                v-for="(field, index) of fields"
                            >
                                <div
                                    class="mb-1 dynamic-table-config__column-list-item p-1 border border-secondary"
                                >
                                    <b-row
                                        :key="index"
                                    >
                                        <b-col
                                            cols="10"
                                            md="auto"
                                            class="d-flex align-items-center"
                                        >
                                            <b-form-checkbox
                                                v-model="field.checked"
                                                :disabled="field.required"
                                            />
                                            <div>
                                                <strong class="mr-1">
                                                    {{ field.name }}
                                                </strong>
                                                <b-badge
                                                    v-for="(label, _index) of field.groupLabels"
                                                    :key="_index"
                                                    :variant="getBadgeClassByGroupLabel(label)"
                                                    class="mr-1"
                                                >
                                                    {{ getGroupLabelTitle(label) }}
                                                </b-badge>
                                            </div>
                                        </b-col>

                                        <b-col align-self="center">
                                            <span class="pull-right dynamic-table-config__column-list-item-options">
                                                <a class="btn btn-default btn-sm"
                                                   @click="field.showSettings = !field.showSettings"
                                                >
                                                    <feather-icon icon="ToolIcon" />
                                                </a>
                                                <span
                                                    class="btn btn-default btn-sm dynamic-table-config__column-list-item-sort-handle"
                                                >
                                                    <feather-icon icon="AlignJustifyIcon" />
                                                </span>
                                            </span>
                                        </b-col>
                                    </b-row>
                                    <div
                                        v-if="field.showSettings"
                                        :key="index"
                                    >
                                        <b-row
                                            class="mt-1"
                                        >
                                            <b-col
                                                cols="3"
                                            >
                                                Column name:
                                            </b-col>
                                            <b-col
                                                cols="9"
                                            >
                                                <b-form-input
                                                    v-model="field.label"
                                                    placeholder="Column name:"
                                                />
                                            </b-col>
                                        </b-row>
                                        <b-row
                                            class="mt-1"
                                        >
                                            <b-col
                                                cols="3"
                                            >
                                                Dynamic Column width:
                                            </b-col>
                                            <b-col
                                                cols="9"
                                            >
                                                <b-form-checkbox
                                                    v-model="field.columnWidthDynamic"
                                                />
                                            </b-col>
                                        </b-row>
                                        <b-row
                                            v-if="!field.columnWidthDynamic"
                                            class="mt-1"
                                        >
                                            <b-col
                                                cols="3"
                                            />
                                            <b-col
                                                cols="9"
                                            >
                                                <b-input-group
                                                    append="px"
                                                >
                                                    <b-form-input
                                                        v-model="field.columnWidth"
                                                    />
                                                </b-input-group>
                                            </b-col>
                                        </b-row>
                                    </div>
                                </div>
                            </template>
                        </draggable>
                    </template>
                </template>
            </b-container>
            <b-container
                v-else
                fluid
            >
                <b-row>
                    <b-col
                        cols="3"
                    >
                        Layout name:
                    </b-col>
                    <b-col
                        cols="9"
                    >
                        <b-form-input
                            v-model="newLayoutName"
                        />
                    </b-col>
                </b-row>
                <b-row>
                    <b-col
                        cols="12"
                        md="auto"
                        class="pull-right"
                    >
                        <b-button
                            variant="success"
                            class="mr-1"
                            :disabled="loading"
                            @click="addLayout"
                        >
                            {{ loading ? 'Creating...' : 'Create layout' }}
                        </b-button>
                        <b-button
                            variant="light-dark"
                            @click="addingLayout = false"
                        >
                            Cancel
                        </b-button>
                    </b-col>
                </b-row>
            </b-container>
        </b-modal>
        <modal-confirm
            slot="modal"
            text="Are you sure you want to delete this layout?"
            title="Confirm deleting layout"
            confirm-loading-text="Deleting..."
            confirm-button-text="Confirm"
            :visible="deleteLayoutModalVisible"
            :loading="loading"
            @confirmed="deleteLayout()"
            @close="closeDeleteLayoutModal()"
        />
    </div>
</template>

<style scoped>
.dynamic-table-config__column-list-item.sortable-drag {
    background: #fff;
}

.dynamic-table-config__column-list-item.ghost {
    background-color: #eee;
    opacity: 0.5;
}

</style>
<script>
import Draggable from 'vuedraggable'
import Axios from 'axios'
import {DynamicTableFieldGroupNames} from '@/constants'
import ModalConfirm from '../../Modals/ModalConfirm.vue'
import {DynamicTableFieldTypes} from '../../../structures/DynamicTableField'
import {
    BModal,
    BButton,
    BFormInput,
    BContainer,
    BRow,
    BCol,
    BFormCheckbox,
    BBadge,
    BButtonGroup,
    BInputGroup,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
    components: {
        ModalConfirm,
        Draggable,
        BModal,
        BButton,
        BFormInput,
        BContainer,
        BRow,
        BCol,
        BFormCheckbox,
        BBadge,
        BButtonGroup,
        BInputGroup,
    },
    directives: {
        Ripple,
    },
    props: {
        visible: {
            type: Boolean,
            default: false,
        },
        tableKey: {
            type: String,
            required: true,
        },
        allowFieldCopying: {
            type: Boolean,
            default: false,
        },
        initialLayoutId: {
            type: Number,
            default: 0,
        },
        availableFields: {
            type: Array,
            default: () => [],
        },
        loadedLayouts: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            loading: true,
            errors: [],
            fields: [],
            addingLayout: false,
            deleteLayoutModalVisible: false,
            currentLayoutId: 0,
            newLayoutName: '',
            currentLayout: {
                id: 0,
                name: '',
                isDefault: false,
                isPublic: false,
            },
            disableLayoutAutoSave: false,
            saveTriggerTimeout: null,
        }
    },
    computed: {
        addingLayoutVisible() {
            return this.addingLayout
        },
        layoutEditingDisabled() {
            return this.addingLayoutVisible && !this.loading
        },
        canSave() {
            return this.visible && !this.disableLayoutAutoSave
        },
        layouts() {
            return this.loadedLayouts
        },
        fieldsForTable() {
            return this.availableFields
        }
    },
    watch: {
        currentLayoutId(newValue) {
            for (const layout of this.layouts) {
                if (layout.id === newValue) {
                    this.currentLayout = layout
                    this.populateFieldsForSettingsList(layout.layout)
                }
            }
        },
        currentLayout: {
            handler() {
                this.triggerSaveLayout()
            },
            deep: true,
        },
        fields: {
            handler() {
                this.currentLayout.layout = this.fields.filter(field => field.checked)
                this.triggerSaveLayout()
            },
            deep: true,
        },
        visible(newVal) {
            if (newVal) {
                this.pickLayout()
            }

            if (this.visible === false) {
                window.removeEventListener('keyup', this.onEscapeKeyUp)
            } else {
                window.addEventListener('keyup', this.onEscapeKeyUp)
            }
        },
    },
    created() {
        this.loading = false
    },
    methods: {
        resetDefaultLayout() {
            this.fields = JSON.parse(JSON.stringify(this.availableFields))
            this.appendFieldInformation(this.fields)
            this.resetDefaultRequiredFields()
        },
        deleteLayout() {
            this.loading = true
            Axios({
                url: `${process.env.VUE_APP_API_HTTP_ROOT}table-layouts/delete`,
                data: {
                    id: this.currentLayout.id,
                    table_key: this.tableKey,
                },
                method: 'DELETE',
            })
                .finally(() => {
                    this.$emit('reload')
                    this.closeDeleteLayoutModal()
                    this.loading = false
                })

        },
        closeDeleteLayoutModal() {
            this.deleteLayoutModalVisible = false
            this.loading = false
        },
        addLayout() {
            this.loading = true
            this.errors = []
            this.resetDefaultLayout()
            const fieldsToSave = this.fields.filter(field => field.checked)
            Axios({
                url: `${process.env.VUE_APP_API_HTTP_ROOT}table-layouts/create`,
                data: {
                    table_key: this.tableKey,
                    name: this.newLayoutName,
                    is_default: false,
                    layout: fieldsToSave,
                },
                method: 'POST',
            })
                .then(response => {
                    const layout = response.data.data
                    layout.isDefault = !!layout.is_default

                    this.layouts.push(layout)
                    this.currentLayoutId = layout.id
                    this.addingLayout = false
                })
                .catch(errors => {
                    for (const errorGroupKey of Object.keys(errors.response.data.errors)) {
                        const errorGroup = errors.response.data.errors[errorGroupKey]
                        this.errors = this.errors.concat(errorGroup)
                    }
                })
                .finally(() => {
                    this.loading = false
                })
        },
        startAddingLayout() {
            this.loading = false
            this.addingLayout = true
            this.currentLayoutId = 0
        },
        triggerSaveLayout() {
            if (this.disableLayoutAutoSave) {
                return
            }
            clearTimeout(this.saveTriggerTimeout)
            this.saveTriggerTimeout = setTimeout(this.saveLayout, 500)
        },
        saveLayout() {
            if (!this.canSave) {
                return
            }
            if (this.currentLayout.id === 0) {
                return
            }
            this.disableLayoutAutoSave = true

            const fieldsToSave = this.fields.filter(field => field.checked)

            Axios({
                url: `${process.env.VUE_APP_API_HTTP_ROOT}table-layouts/update`,
                data: {
                    id: this.currentLayout.id,
                    table_key: this.tableKey,
                    name: this.currentLayout.name,
                    is_default: this.currentLayout.isDefault,
                    is_public: this.currentLayout.isPublic,
                    layout: fieldsToSave,
                },
                method: 'POST',
            })
                .catch(errors => {
                    this.loading = false
                    for (const errorGroupKey of Object.keys(errors.response.data.errors)) {
                        const errorGroup = errors.response.data.errors[errorGroupKey]
                        this.errors = this.errors.concat(errorGroup)
                    }
                })
                .finally(() => {
                    this.disableLayoutAutoSave = false
                })
        },
        populateMissingFields(fields) {
            for (const field of this.availableFields) {
                if (this.alreadyInTheFieldSet(field, fields)) {
                    continue
                }

                if (this.isDefinedDynamically(field)) {
                    fields.push(this.createDynamicField(field, false))
                    continue
                }

                fields.push(this.createStaticField(field, false))
            }
        },
        alreadyInTheFieldSet(field, fields) {
            for (const existingField of fields) {
                if (field.key === existingField.key) {
                    return true
                }
            }
            return false
        },
        isDefinedDynamically(fieldToCheck) {
            return !fieldToCheck.staticallyDefined
        },
        createDynamicField(customField, markAsChecked) {
            return {
                type: customField.type || DynamicTableFieldTypes.TEXT,
                name: customField.name,
                key: customField.key,
                enabledByDefault: false,
                valueKey: customField.key,
                checked: markAsChecked ? customField.enabledByDefault : false,
                showSettings: false,
                label: customField.name,
                columnWidth: 200,
                columnWidthDynamic: false,
                customFieldId: customField.id ? customField.id : 0,
                groupLabels: customField.sources,
                addedToList: true,
            }
        },
        createStaticField(normalField, markAsChecked) {
            return {
                type: normalField.type,
                name: normalField.name,
                key: normalField.key,
                enabledByDefault: normalField.enabledByDefault,
                valueKey: normalField.valueKey,
                checked: markAsChecked ? normalField.enabledByDefault : false,
                showSettings: false,
                label: normalField.name,
                columnWidth: 200,
                columnWidthDynamic: false,
                customFieldId: null,
                groupLabels: normalField.groupLabels,
                addedToList: true,
            }
        },
        populateFieldsForSettingsList(fields) {
            let tmpFields = JSON.parse(JSON.stringify(fields))
            this.populateDynamicFieldInformation(tmpFields)
            this.populateMissingFields(tmpFields)
            this.appendFieldInformation(tmpFields)
            this.fields = tmpFields
        },
        populateDynamicFieldInformation(fields) {
            fields = fields.map(field => {
                field.checked = true
                field.name = field.label
                if (field.columnWidth) {
                    field.columnWidthDynamic = false
                } else {
                    field.columnWidthDynamic = true
                    field.columnWidth = 200
                }
            })
        },
        pickLayout() {
            this.loading = false
            const pickedLayout = this.layouts.filter(layout => layout.id === this.initialLayoutId)
            if (pickedLayout) {
                this.currentLayoutId = this.initialLayoutId
                return
            } else {
                const firstLayout = this.layouts.filter(layout => layout.id > 0)
                if (firstLayout) {
                    this.currentLayoutId = firstLayout.id
                    return
                }
            }

            this.currentLayoutId = 0
        },
        getBadgeClassByGroupLabel(groupLabelName) {
            const badgeMap = {}
            badgeMap[DynamicTableFieldGroupNames.KEY_CUSTOM_FIELD] = 'danger'
            badgeMap[DynamicTableFieldGroupNames.KEY_DEFAULT] = 'success'
            badgeMap[DynamicTableFieldGroupNames.KEY_LINNWORKS] = 'info'
            badgeMap[DynamicTableFieldGroupNames.KEY_AMAZON] = 'warning'

            return badgeMap[groupLabelName] ? badgeMap[groupLabelName] : ''
        },
        getGroupLabelTitle(groupLabelName) {
            const titleMap = {}
            titleMap[DynamicTableFieldGroupNames.KEY_CUSTOM_FIELD] = 'Custom field'
            titleMap[DynamicTableFieldGroupNames.KEY_DEFAULT] = 'Default field'
            titleMap[DynamicTableFieldGroupNames.KEY_AMAZON] = 'Amazon'
            titleMap[DynamicTableFieldGroupNames.KEY_LINNWORKS] = 'Linnworks'

            return titleMap[groupLabelName] ? titleMap[groupLabelName] : ''
        },
        appendFieldInformation(layout) {
            for (const field of layout) {
                let fieldFound = false
                let tableField
                for (tableField of this.fieldsForTable) {
                    if (tableField.key === field.key) {
                        fieldFound = true
                        break
                    }
                }
                if (!fieldFound) {
                    continue
                }

                field.groupLabels = tableField.sources ? tableField.sources : tableField.groupLabels
                field.showSettings = false
            }
        },
        resetDefaultRequiredFields() {
            const newFieldSet = []
            for (const field of this.fields) {
                if (this.isDefinedDynamically(field)) {
                    newFieldSet.push(this.createDynamicField(field, true))
                    continue
                }

                newFieldSet.push(this.createStaticField(field, true))
            }

            this.fields = newFieldSet
        },
        onEscapeKeyUp(event) {
            if (event.which === 27) {
                this.$emit('close')
            }
        },
    },
}
</script>
