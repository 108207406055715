<template>
    <div>
        <b-container
            fluid
            style="padding-right: 0 !important;"
        >
            <b-row
                align-h="end"
            >
                <b-col
                    cols="2"
                >
                    <label
                        for="item-list-filters__search"
                    >
                        Search:
                    </label>
                    <b-form-input
                        id="item-list-filters__search"
                        v-model="search"
                        size="sm"
                    />
                </b-col>
                <b-col cols="2">
                    <label class="" for="item-list-filters-item__box">Box:</label>
                    <select id="item-list-filters-item__box" v-model="selectedBox" class="form-control form-control-sm">
                        <option value="">
                            All boxes
                        </option>
                        <template v-for="box in boxes">
                            <option :key="box.index" :value="box.index">
                                Box {{ box.index }}
                            </option>
                        </template>
                    </select>
                </b-col>
                <b-col
                    cols="1"
                    md="auto"
                >
                    <label
                        for="filter-info__head-tool-label"
                    >
                        Show:
                    </label>
                    <br>
                    <XcDropdownButton
                        id="filter-info__head-tool-label"
                        :buttons="[
                            {
                                title: ShipmentFilterOptions.STATUS.ALL,
                                action: showAll
                            },
                            {
                                title: ShipmentFilterOptions.STATUS.COMPLETED,
                                action: showProcessed
                            },
                            {
                                title: ShipmentFilterOptions.STATUS.PENDING,
                                action: showProcessable
                            },
                            {
                                title: ShipmentFilterOptions.STATUS.PENDING_LESS,
                                action: showProcessableQtyLess
                            },
                            {
                                title: ShipmentFilterOptions.STATUS.PENDING_MORE,
                                action: showProcessableQtyMore
                            },
                        ]"
                        :primary-text="itemStatusName"
                        :extra-classes="['filter-info__dropdown--extra-small']"
                    />
                </b-col>
                <b-col
                    cols="1"
                    md="auto"
                >
                    <label
                        for="filter-info__head-tool-label_type"
                    >
                        Label type:
                    </label>

                    <XcDropdownButton
                        id="filter-info__head-tool-label_type"
                        :buttons="[
                            {
                                title: ShipmentFilterOptions.TYPE.ALL,
                                action: showFnskuTypeAll
                            },
                            {
                                title: ShipmentFilterOptions.TYPE.STICKERLESS,
                                action: showFnskuTypeStickerless
                            },
                            {
                                title: ShipmentFilterOptions.TYPE.NON_STICKERLESS,
                                action: showFnskuTypeNonStickerless
                            },
                        ]"
                        :primary-text="itemTypeName"
                        :extra-classes="['filter-info__dropdown--extra-small']"
                    />
                </b-col>
                <b-col
                    cols="1"
                    md="auto"
                >
                    <label
                        for="filter-info__head-tool-label_clear"
                    >
                        Action:
                    </label>
                    <br>
                    <b-button
                        id="filter-info__head-tool-label_clear"
                        variant="info"
                        size="sm"
                        @click="clearFilters()"
                    >
                        <span
                            class="small"
                        >
                            Clear filters
                        </span>
                    </b-button>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>
import Store from '@/store'
import { ShipmentFilterOptions } from '@/constants'
import XcDropdownButton from '@/components/Misc/XcDropdownButton.vue'
import {
    BContainer,
    BRow,
    BCol,
    BButton,
    BFormInput,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
    components: {
        XcDropdownButton,
        BContainer,
        BRow,
        BCol,
        BButton,
        BFormInput,
    },
    directives: {
        Ripple,
    },
    props: {
        boxes: {
            default: () => [],
            type: Array,
        },
    },
    data() {
        return {
            ShipmentFilterOptions,
        }
    },
    computed: {
        itemShowType() {
            return Store.getters.ShipmentItemFilters.status
        },
        itemFnskuType() {
            return Store.getters.ShipmentItemFilters.type
        },
        search: {
            get() {
                return Store.getters.ShipmentItemFilters.search
            },
            set(value) {
                this.$store.commit('shipment_item_filters_search', value)
            },
        },
        selectedBox: {
            get() {
                return Store.getters.ShipmentItemFilters.box
            },
            set(value) {
                this.$store.commit('shipment_item_filters_box', value)
            },
        },
        itemStatusName() {
            return Store.getters.ShipmentItemFilters.status
        },
        itemTypeName() {
            return Store.getters.ShipmentItemFilters.type
        },
    },
    watch: {
    },
    methods: {
        showAll() {
            this.switchShowType(ShipmentFilterOptions.STATUS.ALL)
        },
        showProcessed() {
            this.switchShowType(ShipmentFilterOptions.STATUS.COMPLETED)
        },
        showProcessable() {
            this.switchShowType(ShipmentFilterOptions.STATUS.PENDING)
        },
        showProcessableQtyLess() {
            this.switchShowType(ShipmentFilterOptions.STATUS.PENDING_LESS)
        },
        showProcessableQtyMore() {
            this.switchShowType(ShipmentFilterOptions.STATUS.PENDING_MORE)
        },
        switchShowType(type) {
            this.$store.commit('shipment_item_filters_status', type)
        },
        showFnskuTypeAll() {
            this.switchFnskuType(ShipmentFilterOptions.TYPE.ALL)
        },
        showFnskuTypeStickerless() {
            this.switchFnskuType(ShipmentFilterOptions.TYPE.STICKERLESS)
        },
        showFnskuTypeNonStickerless() {
            this.switchFnskuType(ShipmentFilterOptions.TYPE.NON_STICKERLESS)
        },
        switchFnskuType(type) {
            this.$store.commit('shipment_item_filters_type', type)
        },
        clearFilters() {
            this.$store.commit('shipment_item_filters_status', ShipmentFilterOptions.STATUS.ALL)
            this.$store.commit('shipment_item_filters_type', ShipmentFilterOptions.TYPE.ALL)
            this.$store.commit('shipment_item_filters_box', '')
            this.$store.commit('shipment_item_filters_search', '')
        },

    },
}
</script>

<style>
    .item-list-filters__input--small {
        height: 30px;
        padding: 3px 10px;
        width: auto;
        display: inline-block;
        margin-top: 7px;
        margin-left: 5px;
    }
    .filter-info__head-info-block {
        margin-left: 15px;
    }
    .filter-info__head-tool-label {
        margin-bottom: 0;
    }
    .filter-info {
        display: flex;
        flex-wrap: wrap;
    }
    .filter-info__dropdown--extra-small {
        margin-left: 5px !important;
        height: 30px;
    }
</style>
