<template>
    <div>
        <div class="items-table">
            <div class="row items-table__extra-info">
                <div class="items-table__item-count col-md-9">
                    Showing {{ visibleItemCount }} of {{ rows.length }} SKUs.<br>
                </div>
                <div class="items-table__item col-md-3">
                    <div class="pull-right">
                        <label class="items-table__item-option-label">
                            Selected layout:
                        </label>
                        <dynamic-table-picker
                            :default-fields="tableFields"
                            table-key="ct_key_shipment_items_table"
                            :current-layout-id="currentLayoutId"
                            @layout-changed="changeTableLayout"
                        />
                    </div>
                </div>
            </div>
            <div>
                <b-table
                    :items="rowsToShow"
                    :fields="currentLayout"
                    :tbody-tr-class="rowClass"
                    sticky-header="1000px"
                    responsive
                    no-border-collapse
                >
                    <template #cell(shipment-item-number)="row">
                        {{ row.index + 1 }}
                    </template>

                    <template #cell(shipment-item-combined-codes)="row">
                        <strong>{{ row.item.fn_sku }}</strong><br>
                        EAN: {{ row.item.external_id }}<br>
                        SKU: {{ row.item.merchant_sku }}
                    </template>

                    <template #cell(shipment-item-completed-count)="row">
                        <div style="min-width: 150px;">
                            <b-badge :variant="getStatusBadgeClass(row)">
                                {{ row.item.listed }}
                            </b-badge>
                            of {{ parseInt(row.item.shipped) }}
                            <template v-if="itemBox + '' !== ''">
                                <br>
                                {{ getCountInBox(row.item.boxesWithItems, itemBox) }} in Box {{ parseInt(itemBox) }}
                            </template>
                        </div>
                    </template>

                    <template #cell(linnworksDefaultImageSource)="data">
                        <img :src="data.value" alt="">
                    </template>

                    <template #cell(shipment-item-count-input)="row">
                        <b-form
                            inline
                            @submit.stop.prevent
                        >
                            <b-form-checkbox
                                v-model="row.item.shouldPrint"
                            />
                            <b-form-input
                                v-model="row.item.itemsToPrint"
                                :value="row.item.itemsToPrint = !row.item.itemsToPrint ? itemsRemaining(row.item) : row.item.itemsToPrint"
                                placeholder="Count"
                                type="number"
                                class="form-control form-control-sm m-input print-options__count-input"
                                style="max-width: 75px;"
                            />
                        </b-form>
                    </template>

                    <template #head(shipment-item-count-input)="">
                        <b-container
                            fluid="sm"
                        >
                            <b-row>
                                <b-col
                                    cols="2"
                                    align-self="center"
                                >
                                    <b-form-checkbox
                                        :checked="allItemsChecked"
                                        @change="checkAllCheckboxes"
                                    />
                                </b-col>
                                <b-col
                                    cols="9"
                                >
                                    <XcDropdownButton
                                        :disabled="!atLeastOneItemChecked"
                                        :loading="itemActionDropdownLoading"
                                        :buttons="[
                                            {
                                                title: 'Print A4 labels',
                                                action: printA4Labels
                                            },
                                            {
                                                title: 'Mark as Completed',
                                                action: markAsCompleted
                                            },
                                            {
                                                title: 'Mark as Pending',
                                                action: markAsPending
                                            },
                                        ]"
                                        primary-text="Print"
                                        :primary-action="printItemLabels"
                                    />
                                </b-col>
                            </b-row>
                        </b-container>
                    </template>

                    <template #cell(linnworks-sync-status)="row">
                        <template v-if="row.item.linnworksShipmentUpdate.hasUpdate">
                            <template v-if="row.item.linnworksShipmentUpdate.wasSuccessful">
                                <b-badge
                                    v-if="row.item.linnworksShipmentUpdate.amount > 0"
                                    variant="success"
                                >
                                    {{ row.item.linnworksShipmentUpdate.amount }} of {{ row.item.shipped }} removed
                                </b-badge>
                                <b-badge
                                    v-if="row.item.linnworksShipmentUpdate.amount > 0 && row.item.linnworksShipmentUpdate.reverted"
                                    variant="warning"
                                >
                                    {{ row.item.linnworksShipmentUpdate.amount }} of {{ row.item.shipped }} reverted
                                </b-badge>
                            </template>
                            <template v-if="!row.item.linnworksShipmentUpdate.wasSuccessful">
                                <b-badge
                                    variant="danger"
                                >
                                    Had error
                                </b-badge>
                                <p class="m--font-danger">
                                    {{ row.item.linnworksShipmentUpdate.message }}
                                </p>
                            </template>
                        </template>
                        <template v-else>
                            <b-badge
                                variant="info"
                            >
                                Waiting for sync...
                            </b-badge>
                        </template>
                    </template>
                </b-table>
            </div>
        </div>
    </div>
</template>

<style scoped>
@import url('../../assets/vendors/bootstrap-select/dist/css/bootstrap-select.css');

.items-table {
    padding-top: 10px;
}

.items-table__item-count {
    line-height: 30px;
}

.items-table__item-option-label {
    margin-right: 10px;
}

.items-table__extra-info {
    margin-bottom: 10px;
}
table {
    position: relative;
    border-collapse: collapse;
}
th {
    position: sticky;
    top: 70px;
    background: #f4f3f8;
}
</style>
<script>
import Axios from 'axios'
import Store from '@/store'
import { ShipmentFilterOptions } from '@/constants'
import XcDropdownButton from '@/components/Misc/XcDropdownButton.vue'
import DynamicTablePicker from '../Misc/DynamicTable/DynamicTablePicker.vue'
import { DynamicTableField, DynamicTableFieldTypes } from '../../structures/DynamicTableField'
import {
    BTable,
    BContainer,
    BRow,
    BCol,
    BBadge,
    BFormCheckbox,
    BFormInput,
    BForm,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
    components: {
        DynamicTablePicker,
        XcDropdownButton,
        BTable,
        BContainer,
        BRow,
        BCol,
        BBadge,
        BFormCheckbox,
        BFormInput,
        BForm,
        // eslint-disable-next-line vue/no-unused-components
        ToastificationContent,
    },
    props: {
        loading: {
            type: Boolean,
            default: true,
        },
        shipmentId: {
            type: Number,
            required: true,
        },
        boxes: {
            default: () => [],
            type: Array,
        },

    },
    data() {
        return {
            totalRecords: 0,
            ShipmentFilterOptions,
            itemsToPrint: [],
            itemActionDropdownLoading: false,
            currentLayout: [],
            currentLayoutId: 0,
            layoutRemap: [],
            tableFields: [
                new DynamicTableField({
                    type: DynamicTableFieldTypes.TEXT,
                    staticallyDefined: true,
                    name: 'Nr.',
                    key: 'shipment-item-number',
                    enabledByDefault: true,
                    groupLabels: ['default'],
                }),
                new DynamicTableField({
                    type: DynamicTableFieldTypes.CUSTOM,
                    staticallyDefined: true,
                    name: 'FNSKU / EAN / SKU',
                    key: 'shipment-item-combined-codes',
                    enabledByDefault: true,
                    data: {
                        customName: 'Combined values',
                    },
                    groupLabels: ['default'],
                }),
                new DynamicTableField({
                    type: DynamicTableFieldTypes.TEXT,
                    staticallyDefined: true,
                    name: 'Name',
                    key: 'shipment-item-title',
                    enabledByDefault: true,
                    groupLabels: ['default'],

                }),
                new DynamicTableField({
                    type: DynamicTableFieldTypes.CUSTOM,
                    staticallyDefined: true,
                    name: 'Count',
                    key: 'shipment-item-completed-count',
                    enabledByDefault: true,
                    data: {
                        customName: 'Item count',
                    },
                    groupLabels: ['default'],

                }),
                new DynamicTableField({
                    type: DynamicTableFieldTypes.TEXT,
                    staticallyDefined: true,
                    name: 'FNSKU',
                    key: 'shipment-item-fn_sku',
                    enabledByDefault: false,
                    groupLabels: ['default'],

                }),
                new DynamicTableField({
                    type: DynamicTableFieldTypes.TEXT,
                    staticallyDefined: true,
                    name: 'ASIN',
                    key: 'shipment-item-asin_code',
                    enabledByDefault: false,
                    groupLabels: ['default'],

                }),
                new DynamicTableField({
                    type: DynamicTableFieldTypes.TEXT,
                    staticallyDefined: true,
                    name: 'SKU',
                    key: 'shipment-item-merchant_sku',
                    enabledByDefault: false,
                    groupLabels: ['default'],

                }),
                new DynamicTableField({
                    type: DynamicTableFieldTypes.TEXT,
                    staticallyDefined: true,
                    name: 'EAN',
                    key: 'shipment-item-external_id',
                    enabledByDefault: false,
                    groupLabels: ['default'],

                }),
                new DynamicTableField({
                    type: DynamicTableFieldTypes.TEXT,
                    staticallyDefined: true,
                    name: 'Scanned',
                    key: 'shipment-item-shipped',
                    enabledByDefault: false,
                    groupLabels: ['default'],

                }),
                new DynamicTableField({
                    type: DynamicTableFieldTypes.TEXT,
                    staticallyDefined: true,
                    name: 'Total item count',
                    key: 'shipment-item-listed',
                    enabledByDefault: false,
                    groupLabels: ['default'],

                }),
                new DynamicTableField({
                    type: DynamicTableFieldTypes.CUSTOM,
                    staticallyDefined: true,
                    name: 'Linnworks sync status',
                    key: 'linnworks-sync-status',
                    enabledByDefault: false,
                    data: {
                        customName: 'Linnworks sync status',
                    },
                    groupLabels: ['default'],

                }),
                // this should always be last one, otherwise UI looks crappy
                new DynamicTableField({
                    type: DynamicTableFieldTypes.INPUT,
                    staticallyDefined: true,
                    name: 'Count input',
                    key: 'shipment-item-count-input',
                    enabledByDefault: true,
                    data: {
                        type: 'number',
                    },
                    required: true,
                    groupLabels: ['default'],
                }),
            ],
        }
    },
    computed: {
        rows() {
            return Store.getters.shipmentItems
        },
        itemShowType() {
            return Store.getters.ShipmentItemFilters.status
        },
        itemFnskuType() {
            return Store.getters.ShipmentItemFilters.type
        },
        itemBox() {
            return Store.getters.ShipmentItemFilters.box
        },
        itemSearch() {
            return Store.getters.ShipmentItemFilters.search
        },
        visibleItemCount() {
            let count = 0
            for (const row of this.rows) {
                if (this.shouldShow(row)) {
                    count += 1
                }
            }

            return count
        },
        allItemsChecked: {
            get() {
                if (this.rows.length === 0) {
                    return false
                }
                for (const row of this.rows) {
                    if (!row.shouldPrint) {
                        return false
                    }
                }

                return true
            },
        },
        atLeastOneItemChecked() {
            for (const row of this.rows) {
                if (row.shouldPrint) {
                    return true
                }
            }

            return false
        },
        rowsToShow() {
            return this.rows.filter(row => this.shouldShow(row))
        },
    },
    methods: {
        rowClass(item, type) {
            const shipped = parseInt(item.shipped)
            const listed = parseInt(item.listed)
            if (item.forceCompleted()) {
                return 'table-success'
            }

            if (listed === shipped) {
                return 'table-success'
            } if (listed > shipped) {
                return 'table-warning'
            }
            return 'table-danger'
            // if (!item || type !== 'row') return
            // if (item.status === 'awesome') return 'table-success'
        },
        isStickerless(row) {
            return row.fn_sku.toLowerCase().charAt(0) === 'b'
        },
        getStatusColorClass(row) {
            const shipped = parseInt(row.shipped)
            const listed = parseInt(row.listed)
            if (row.forceCompleted()) {
                return 'm-datatable__row--success'
            }

            if (listed === shipped) {
                return 'm-datatable__row--success'
            } if (listed > shipped) {
                return 'm-datatable__row--warning'
            }
            return 'm-datatable__row--danger'
        },
        getStatusBadgeClass(row) {
            const shipped = parseInt(row.item.shipped)
            const listed = parseInt(row.item.listed)
            if (listed === shipped) {
                return 'success'
            } if (listed > shipped) {
                return 'warning'
            }
            return 'danger'
        },
        itemsRemaining(row) {
            const itemsRemaining = row.shipped - row.listed

            return itemsRemaining > 0 ? itemsRemaining : 0
        },
        shouldShow(row) {
            // check all filters one by one. If any of those fails, hide the row
            // filter by status
            if (this.itemShowType === ShipmentFilterOptions.STATUS.COMPLETED && !this.isCompleted(row)) {
                return false
            }
            if (this.itemShowType === ShipmentFilterOptions.STATUS.PENDING && this.isCompleted(row)) {
                return false
            }
            if (this.itemShowType === ShipmentFilterOptions.STATUS.PENDING_LESS && (this.moreOrEnoughScanned(row) || this.isCompleted(row))) {
                return false
            }
            if (this.itemShowType === ShipmentFilterOptions.STATUS.PENDING_MORE && (this.lessOrEnoughScanned(row) || this.isCompleted(row))) {
                return false
            }

            // filter by fnsku type
            if (this.itemFnskuType === ShipmentFilterOptions.TYPE.STICKERLESS && !this.isStickerless(row)) {
                return false
            }
            if (this.itemFnskuType === ShipmentFilterOptions.TYPE.NON_STICKERLESS && this.isStickerless(row)) {
                return false
            }

            // filter by box
            if (this.itemBox !== '') {
                // noinspection EqualityComparisonWithCoercionJS
                const box = this.boxes.filter(box => box.index == this.itemBox)[0]
                const itemsInBox = row.boxesWithItems[box.index] ?? 0
                if (!box || !itemsInBox) {
                    return false
                }
            }

            // filter search
            if (this.itemSearch !== '') {
                const search = this.itemSearch.toString().toLowerCase()
                const foundInTitle = row.title.toString().toLowerCase().indexOf(search) !== -1
                const foundInEan = row.external_id.toString().toLowerCase().indexOf(search) !== -1
                const foundInFnku = row.fn_sku.toString().toLowerCase().indexOf(search) !== -1
                const foundInsku = row.merchant_sku.toString().toLowerCase().indexOf(search) !== -1

                if (!foundInTitle && !foundInEan && !foundInFnku && !foundInsku) {
                    return false
                }
            }

            return true
        },
        isCompleted(row) {
            if (row.status === 1) {
                return true
            }

            return parseInt(row.listed) === parseInt(row.shipped)
        },
        lessOrEnoughScanned(row) {
            return parseInt(row.listed) <= parseInt(row.shipped)
        },
        moreOrEnoughScanned(row) {
            return parseInt(row.listed) >= parseInt(row.shipped)
        },
        printItemLabels() {
            this.downloadLabelFile('small')
        },
        printA4Labels() {
            this.downloadLabelFile('a4')
        },
        downloadLabelFile(type) {
            this.itemsToPrint = []
            this.itemActionDropdownLoading = true
            const items = this.rows.filter(item => item.shouldPrint).map(item => ({ id: item.id, count: item.itemsToPrint }))
            Axios({
                url: `${process.env.VUE_APP_API_HTTP_ROOT}shipments/get-stickers-${type}`,
                data: { items },
                method: 'POST',
            })
                .then(resp => {
                    this.saveBlob('barcodes.pdf', resp.data.file)
                    this.itemActionDropdownLoading = false
                })
                .catch(() => {
                    this.itemActionDropdownLoading = false
                })
        },
        mmToPt(mm) {
            return Math.round((mm * 72) / 25.4)
        },
        saveBlob(filename, data) {
            const linkSource = `data:application/pdf;base64,${data}`
            const downloadLink = document.createElement('a')
            downloadLink.href = linkSource
            downloadLink.download = filename
            document.body.appendChild(downloadLink)
            downloadLink.click()
            document.body.removeChild(downloadLink)
        },
        getDate() {
            const today = new Date()
            let dd = today.getDate()
            let mm = today.getMonth() + 1 // January is 0!

            const yy = today.getFullYear()
            if (dd < 10) {
                dd = `0${dd}`
            }
            if (mm < 10) {
                mm = `0${mm}`
            }

            return `${dd}${mm}${yy}`
        },
        checkAllCheckboxes(checked) {
            for (const row of this.rows) {
                row.shouldPrint = checked
            }
        },
        markAsCompleted() {
            this.markAs('completed')
        },
        markAsPending() {
            this.markAs('pending')
        },
        markAs(type) {
            this.itemActionDropdownLoading = true
            const updateType = type === 'completed' ? 'completed' : 'pending'
            const items = this.rows.filter(item => item.shouldPrint).map(item => item.id)

            const data = { shipment_id: this.shipmentId, item_ids: items, type: updateType }
            Axios({ url: `${process.env.VUE_APP_API_HTTP_ROOT}shipments/change-item-status`, data, method: 'POST' })
                .then(() => {
                    this.itemActionDropdownLoading = false
                    this.$emit('refresh')
                })
                .catch(() => {
                    this.itemActionDropdownLoading = false
                    this.$emit('refresh')
                })
        },
        getCountInBox(boxes, boxIndex) {
            return boxes[boxIndex] ?? 0
        },
        changeTableLayout(layout) {
            this.currentLayoutId = layout.id
            this.currentLayout = layout.layout
            this.$store.commit('setShipmentCustomFields', this.currentLayout)
        },
    },
}
</script>
