<template>
    <div
        v-if="localVisible"
    >
        <b-container
            fluid
        >
            <b-row
                v-if="data.order.errors.length > 0"
            >
                <b-col
                    cols="12"
                >
                    <b-alert
                        show
                        variant="danger"
                    >
                        <h4
                            class="alert-heading"
                        >
                            There are a few unresolved errors!
                        </h4>
                        <ul
                            class="mt-1"
                        >
                            <li
                                v-for="error of data.order.errors"
                            >
                                {{ error }}
                            </li>
                        </ul>
                        <br>
                    </b-alert>
                </b-col>
            </b-row>
            <b-row
                v-if="linnworksStockRemoval.errors.length > 0"
            >
                <b-col
                    cols="12"
                >
                    <b-alert
                        show
                        variant="danger"
                    >
                        <h4
                            class="alert-heading"
                        >
                            There are a few unresolved errors!
                        </h4>
                        <ul
                            class="mt-1"
                        >
                            <li
                                v-for="error of linnworksStockRemoval.errors"
                            >
                                {{ error }}
                            </li>
                        </ul>
                        <br>
                    </b-alert>
                </b-col>
            </b-row>
            <b-row
                v-if="validationErrors.length > 0"
            >
                <b-col
                    cols="12"
                >
                    <b-alert
                        show
                        variant="danger"
                    >
                        <h4
                            class="alert-heading"
                        >
                            There are a few unresolved errors!
                        </h4>
                        <ul
                            class="mt-1"
                        >
                            <li
                                v-for="error of validationErrors"
                            >
                                {{ error }}
                            </li>
                        </ul>
                        <br>
                    </b-alert>
                </b-col>
            </b-row>
        </b-container>

        <b-container
            fluid
        >
            <b-row>
                <b-col
                    cols="12"
                >
                    Linnworks sync status:
                    <b-badge
                        v-if="data.order.linnworksStatus === SyncStatuses.NOT_SET"
                        variant="light-secondary"
                    >
                        Not created
                    </b-badge>
                    <b-badge
                        v-if="data.order.linnworksStatus === SyncStatuses.PENDING"
                        variant="light-warning"
                    >
                        Pending
                    </b-badge>
                    <b-badge
                        v-if="data.order.linnworksStatus === SyncStatuses.COMPLETED"
                        variant="light-success"
                    >
                        Completed
                    </b-badge>
                    <b-badge
                        v-if="data.order.linnworksStatus === SyncStatuses.FAILED"
                        variant="light-danger"
                    >
                        Failed
                    </b-badge>
                </b-col>
            </b-row>
            <b-row>
                <b-col
                    cols="12"
                    class="mt-1"
                >
                    You have set up your settings to have simple order flow while syncing with Linnworks.
                    This means you can print the sipping labels and perform stock removal for the items in the shipment.
                </b-col>
            </b-row>
            <b-row>
                <b-col
                    cols="12"
                    class="mt-1"
                >
                    The default settings will be taken from your configuration in Settings -> Integrations -> Linnworks.
                </b-col>
            </b-row>
            <b-row>
                <b-col
                    cols="12"
                    class="mt-1"
                >
                    You can always edit these settings on per shipment basis under advanced settings.
                </b-col>
            </b-row>
            <b-row>
                <b-col
                    cols="12"
                    class="mt-3"
                    align-self="start"
                >
                    <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="primary"
                        size="sm"
                        @click="advancedOptionVisible = !advancedOptionVisible"
                    >
                        {{ advancedOptionVisible ? 'Hide advanced options' : 'Show advanced options' }}
                    </b-button>
                </b-col>
            </b-row>

            <div
                v-if="advancedOptionVisible"
            >
                <b-row
                    class="mt-2"
                >
                    <b-col
                        cols="3"
                        align-self="center"
                    >
                        Stock location:
                    </b-col>

                    <b-col
                        cols="9"
                    >
                        <v-select
                            id="linnworks-stock-location"
                            v-model="localOrder.linnworksLocationId"
                            label="text"
                            :options="stockLocations"
                            :reduce="text => text.value"
                        />
                    </b-col>
                </b-row>
                <b-row
                    class="mt-1"
                >
                    <b-col
                        cols="3"
                        align-self="center"
                    >
                        Printer:
                    </b-col>
                    <b-col
                        cols="9"
                    >
                        <v-select
                            id="s-printer"
                            v-model="localOrder.linnworksPrinterName"
                            label="text"
                            :searchable="searchable"
                            :options="printers"
                            :reduce="text => text.value"
                        />
                    </b-col>
                </b-row>
                <b-row
                    class="mt-1"
                >
                    <b-col
                        cols="3"
                        align-self="center"
                    >
                        Source: 
                    </b-col>
                    <b-col
                        cols="9"
                    >
                        <b-form-input 
                            v-model="localOrder.linnworksSource"
                            placeholder="Source"
                        />
                    </b-col>
                </b-row>
                <b-row
                    class="mt-1"
                >
                    <b-col
                        cols="3"
                        align-self="center"
                    >
                        Subsource:
                    </b-col>
                    <b-col
                        cols="9"
                    >
                        <b-form-input
                            v-model="localOrder.linnworksSubsource"
                            placeholder="Subsource"
                        />
                    </b-col>
                </b-row>
                <b-row
                    class="mt-5"
                >
                    <b-col
                        cols="12"
                    >
                        Shipping info
                        <hr>
                    </b-col>
                </b-row>
                <b-row
                    class="mt-1"
                >
                    <b-col
                        cols="3"
                        align-self="center"
                    >
                        Shipping method:
                    </b-col>
                    <b-col
                        cols="9"
                    >
                        <v-select
                            id="s-ship-method"
                            v-model="localOrder.linnworksShippingMethodId"
                            label="text"
                            :searchable="searchable"
                            :options="shippingMethods"
                            :reduce="text => text.value"
                        />
                    </b-col>
                </b-row>
                <b-row
                    class="mt-1"
                >
                    <b-col
                        cols="3"
                        align-self="center"
                    >
                        Full name:
                    </b-col>
                    <b-col
                        cols="9"
                    >
                        <b-form-input
                            v-model="localOrder.linnworksAddress.FullName"
                            placeholder="Full name"
                        />
                    </b-col>
                </b-row>
                <b-row
                    class="mt-1"
                >
                    <b-col
                        cols="3"
                        align-self="center"
                    >
                        Company:
                    </b-col>
                    <b-col
                        cols="9"
                    >
                        <b-form-input
                            v-model="localOrder.linnworksAddress.Company"
                            placeholder="Company"
                        />
                    </b-col>
                </b-row>
                <b-row
                    class="mt-1"
                >
                    <b-col
                        cols="3"
                        align-self="center"
                    >
                        Address 1:
                    </b-col>
                    <b-col
                        cols="9"
                    >
                        <b-form-input
                            v-model="localOrder.linnworksAddress.Address1"
                            placeholder="Address 1"
                        />
                    </b-col>
                </b-row>
                <b-row
                    class="mt-1"
                >
                    <b-col
                        cols="3"
                        align-self="center"
                    >
                        Address 2:
                    </b-col>
                    <b-col
                        cols="9"
                    >
                        <b-form-input
                            v-model="localOrder.linnworksAddress.Address2"
                            placeholder="Address 2"
                        />
                    </b-col>
                </b-row>
                <b-row
                    class="mt-1"
                >
                    <b-col
                        cols="3"
                        align-self="center"
                    >
                        Address 3:
                    </b-col>
                    <b-col
                        cols="9"
                    >
                        <b-form-input
                            v-model="localOrder.linnworksAddress.Address3"
                            placeholder="Address 3"
                        />
                    </b-col>
                </b-row>
                <b-row
                    class="mt-1"
                >
                    <b-col
                        cols="3"
                        align-self="center"
                    >
                        City:
                    </b-col>
                    <b-col
                        cols="9"
                    >
                        <b-form-input
                            v-model="localOrder.linnworksAddress.Town"
                            placeholder="City"
                        />
                    </b-col>
                </b-row>
                <b-row
                    class="mt-1"
                >
                    <b-col
                        cols="3"
                        align-self="center"
                    >
                        Region:
                    </b-col>
                    <b-col
                        cols="9"
                    >
                        <b-form-input
                            v-model="localOrder.linnworksAddress.Region"
                            placeholder="Region"
                        />
                    </b-col>
                </b-row>
                <b-row
                    class="mt-1"
                >
                    <b-col
                        cols="3"
                        align-self="center"
                    >
                        Post code:
                    </b-col>
                    <b-col
                        cols="9"
                    >
                        <b-form-input
                            v-model="localOrder.linnworksAddress.PostCode"
                            placeholder="Post code"
                        />
                    </b-col>
                </b-row>
                <b-row
                    class="mt-1"
                >
                    <b-col
                        cols="3"
                        align-self="center"
                    >
                        Country:
                    </b-col>
                    <b-col
                        cols="9"
                    >
                        <v-select
                            id="s-ship-country"
                            v-model="localOrder.linnworksAddress.CountryId"
                            label="text"
                            :searchable="searchable"
                            :options="countries"
                            :reduce="text => text.value"
                        />
                    </b-col>
                </b-row>
                <b-row
                    class="mt-1"
                >
                    <b-col
                        cols="3"
                        align-self="center"
                    >
                        Email address:
                    </b-col>
                    <b-col
                        cols="9"
                    >
                        <b-form-input
                            v-model="localOrder.linnworksAddress.EmailAddress"
                            placeholder="Email address"
                        />
                    </b-col>
                </b-row>
                <b-row
                    class="mt-1"
                >
                    <b-col
                        cols="3"
                        align-self="start"
                    >
                        Phone number:
                    </b-col>
                    <b-col
                        cols="9"
                    >
                        <b-form-input
                            id="linn-address-phonenumber"
                            v-model="localOrder.linnworksAddress.PhoneNumber"
                            placeholder="Phone number"
                        />
                        <label
                            for="linn-address-phonenumber"
                            class="small-text-justify"
                        >
                            This is required to able to create shipping labels and needs to be at least 10 characters long
                        </label>
                    </b-col>
                </b-row>
                <b-row
                    class="mt-1"
                >
                    <b-button
                        variant="primary"
                        size="sm"
                        :disabled="loading"
                        @click="refreshData"
                    >
                        {{ loading ? 'Loading...' : 'Restore default' }}
                    </b-button>
                </b-row>
            </div>

            <hr>
            <b-row
                class="float-right"
            >
                <b-col
                    cols="12"
                >
                    <template 
                        v-if="data.order.linnworksStatus === 1"
                    >
                        <b-button
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            variant="primary"
                            size="sm"
                            disabled
                        >
                            Synchronizing...
                        </b-button>
                    </template>
                    <template
                        v-else
                    >
                        <template v-if="data.settings.linnworksSyncType === Linnworks.TYPE_FULL_ORDER">
                            <b-button
                                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                variant="primary"
                                size="sm"
                                class="mr-1"
                                :disabled="loading" 
                                @click="sync(false)"
                            >
                                <template v-if="loading">
                                    Loading...
                                </template>
                                <template
                                    v-else-if="!loading && data.order.linnworksStatus === 2 && data.order.type === Linnworks.SYNC_TYPE_ORDER"
                                >
                                    Update order
                                </template>
                                <template v-else>
                                    Create order
                                </template>
                            </b-button>
                            <b-button 
                                v-if="data.order.linnworksStatus === 2"
                                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                variant="primary"
                                size="sm"
                                :disabled="loading"
                            >
                                <template v-if="printing">
                                    Printing...
                                </template>
                                <template v-else-if="loading">
                                    Loading...
                                </template>
                                <template v-else>
                                    Print label
                                </template>
                            </b-button>
                            <b-button 
                                v-if="data.order.linnworksStatus === 2 && Access.checkAccess('linnworks_integration.process_order', Access.LEVEL_READ) && data.order.type === Linnworks.SYNC_TYPE_ORDER"
                                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                variant="primary"
                                size="sm"
                                :disabled="loading"
                                @click="processOrder()"
                            >
                                {{ loading ? 'Loading...' : 'Process order' }}
                            </b-button>
                        </template>
                        <template v-else>
                            <b-button
                                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                variant="primary"
                                size="sm"
                                class="mr-1"
                                :disabled="loading"
                                @click="printFakeOrder()"
                            >
                                {{ loading ? 'Loading...' : 'Print Label' }}
                            </b-button>
                            <b-button
                                v-if="canRemoveStock()"
                                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                variant="primary"
                                size="sm"
                                :disabled="loading"
                                @click="removeStock()"
                            >
                                {{ loading ? 'Loading...' : 'Remove stock' }}
                            </b-button>
                            <b-button
                                v-if="canRevertStock()"
                                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                variant="primary"
                                size="sm"
                                class="ml-1"
                                :disabled="loading"
                                @click="revertStockModalVisible = true"
                            >
                                {{ loading ? 'Loading...' : 'Revert stock removal' }}
                            </b-button>
                        </template>
                    </template>
                    <b-button
                        v-if="localOrder.linnworksPdfUrl"
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="primary"
                        size="sm"
                        class="ml-1"
                        :disabled="loading"
                        @click="downloadPdf()"
                    >
                        Open invoice
                    </b-button>
                </b-col>
            </b-row>
        </b-container>
        <linnworks-invoice-product-list-modal
            :default-product-name="data.settings.defaultProductGroupName"
            :default-product-channel-sku="data.settings.defaultProductChannelSku"
            :default-product-price="data.settings.defaultProductGroupPricePerItem"
            :total-product-count="totalItems"
            :visible="invoiceProductListModalVisible"
            :override-items="localOrder.overrideItems"
            @confirmed="sync(true)"
            @close="invoiceProductListModalVisible = false"
        />
    </div>
</template>

<style></style>

<script>
import Axios from 'axios'
import ModalConfirm from '@/components/Modals/ModalConfirm.vue'
import Access from '@/services/Access'
import { Linnworks, SyncStatuses } from '@/constants'
import LinnworksInvoiceProductListModal from './LinnworksInvoiceProductListModal.vue'

import {
    BContainer,
    BRow,
    BCol,
    BBadge,
    BButton,
    BFormInput,
    BAlert,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'

export default {
    components: {
        // eslint-disable-next-line vue/no-unused-components
        LinnworksInvoiceProductListModal,
        // eslint-disable-next-line vue/no-unused-components
        ModalConfirm,
        BContainer,
        BRow,
        BCol,
        BBadge,
        BButton,
        BFormInput,
        BAlert,
        vSelect,
    },
    directives: {
        Ripple,
    },
    props: {
        visible: {
            type: Boolean,
            default: false,
        },
        shipmentId: {
            type: Number,
            required: true,
        },
        address: {
            type: Object,
            default: () => ({
                address1: '',
                address2: '',
                address3: '',
                city: '',
                region: '',
                country: '',
                postCode: '',
                company: '',
            }),
        },
        totalItems: {
            type: Number,
            default: 0,
        },

    },
    data() {
        return {
            Access,
            Linnworks,
            SyncStatuses,
            localVisible: false,
            advancedOptionVisible: false,
            loading: false,
            printing: false,
            data: {
                order: {
                    errors: [],
                    linnworksStatus: 0,
                    countries: [],
                },
                settings: {
                    linnworksSyncType: '',
                    linnworksDefaultShippingPhone: '',
                },
            },
            currentTab: 'order',
            forceDownload: false,
            revertStockModalVisible: false,
            shouldPrintInvoice: false,
            invoiceProductListModalVisible: false,
            printers: [],
            shippingMethods: [],
            stockLocations: [],
            countries: [],
            searchable: true,
            localOrder: {
                linnworksLocationId: '',
                linnworksShippingMethodId: '',
                linnworksTrackingNumber: '',
                linnworksPrinterName: '',
                linnworksSource: '',
                linnworksSubsource: '',
                linnworksPdfUrl: null,
                overrideItems: [],
                linnworksAddress: {
                    EmailAddress: '',
                    Address1: '',
                    Address2: '',
                    Address3: '',
                    Town: '',
                    Region: '',
                    Continent: '',
                    PostCode: '',
                    Country: '',
                    FullName: '',
                    Company: '',
                    PhoneNumber: '',
                    CountryId: '',
                },
            },
            linnworksStockRemoval: {
                status: 0,
                errors: [],
                stockRemoved: false,
                stockRemovalReverted: false,
                totalItemCount: 0,
                successfulItemCount: 0,
                successfulRevertedCount: 0,
            },
        }
    },
    computed: {
        validationErrors() {
            const errors = []
            if (!this.localOrder.linnworksAddress.Address1) {
                errors.push('Address is missing')
            }
            if (!this.localOrder.linnworksAddress.PostCode) {
                errors.push('Post code is missing')
            }
            if (!this.localOrder.linnworksAddress.CountryId) {
                errors.push('Country is missing')
            }
            if (!this.localOrder.linnworksSource) {
                errors.push('Source is missing')
            }

            return errors
        },
    },
    watch: {
        visible(newVal) {
            this.localVisible = newVal
            this.loadData()
        }
    },
    methods: {
        sync(asFakeOrder) {
            this.invoiceProductListModalVisible = false
            this.localOrder.linnworksPdfUrl = null
            this.loading = true
            this.setCountry()
            const action = asFakeOrder ? 'sync-and-print-fake-order' : 'set-shipment-data'
            this.forceDownload = true
            Axios({
                url: `${process.env.VUE_APP_API_HTTP_ROOT}linnworks/${action}`,
                data: { id: this.shipmentId, order: this.localOrder },
                method: 'POST',
            })
                .then(() => {
                    this.loading = false
                    this.loadData()
                })
                .catch(() => {
                    this.loading = false
                })
        },
        processOrder() {
            this.loading = true
            Axios({
                url: `${process.env.VUE_APP_API_HTTP_ROOT}linnworks/process-order`,
                data: { id: this.shipmentId },
                method: 'POST',
            })
                .then(() => {
                    this.loading = false
                    this.loadData()
                })
                .catch(() => {
                    this.loading = false
                })
        },
        removeStock() {
            this.loading = true
            Axios({
                url: `${process.env.VUE_APP_API_HTTP_ROOT}linnworks/remove-stock`,
                data: { id: this.shipmentId, stockRemoval: this.localOrder },
                method: 'POST',
            })
                .then(() => {
                    this.loading = false
                    this.loadData()
                })
                .catch(() => {
                    this.loading = false
                })
        },
        revertStock() {
            this.loading = true
            Axios({
                url: `${process.env.VUE_APP_API_HTTP_ROOT}linnworks/revert-stock-removal`,
                data: { id: this.shipmentId, stockRemoval: this.localOrder },
                method: 'POST',
            })
                .then(() => {
                    this.loading = false
                    this.revertStockModalVisible = false
                    this.loadData()
                })
                .catch(() => {
                    this.loading = false
                })
        },
        setCountry() {
            for (const country of this.data.countries) {
                if (country.CountryId === this.localOrder.linnworksAddress.CountryId) {
                    this.localOrder.linnworksAddress.Country = country.CountryName
                    return
                }
            }
        },
        loadData() {
            this.loading = true
            Axios({
                url: `${process.env.VUE_APP_API_HTTP_ROOT}linnworks/get-shipment-data`,
                data: { id: this.shipmentId },
                method: 'POST',
            })
                .then(resp => {
                    this.loading = false
                    this.data = resp.data
                    const { order } = resp.data
                    const selectedCountry = this.parseCountryFromAddress()
                    const defaultPrinter = this.isValidPrinter(this.data.settings.linnworksPrinterName) ? this.data.settings.linnworksPrinterName : ''
                    this.data.printers.forEach(printer => this.printers.push({
                        text: `${printer.PrinterLocationName}\\${printer.PrinterName} (${printer.Status})`,
                        value: printer.PrinterName,
                    }))
                    this.data.shippingMethods.forEach(shippingMethod => shippingMethod.postalServices.forEach(vendor => this.shippingMethods.push({
                        text: vendor.postalServiceName,
                        value: vendor.pkPostalServiceId,
                    })))
                    this.data.countries.forEach(country => this.countries.push({ text: country.CountryName, value: country.CountryId }))
                    this.data.stockLocations.forEach(stockLocation => this.stockLocations.push({ text: stockLocation.LocationName, value: stockLocation.StockLocationId }))
                    this.localOrder = {
                        linnworksLocationId: order.linnworksLocationId ? order.linnworksLocationId : this.data.settings.linnworksLocationId,
                        linnworksShippingMethodId: order.linnworksShippingMethodId ? order.linnworksShippingMethodId : this.data.settings.linnworksShippingMethodId,
                        linnworksTrackingNumber: order.linnworksTrackingNumber ? order.linnworksTrackingNumber : '',
                        linnworksPrinterName: this.isValidPrinter(order.linnworksPrinterName) ? order.linnworksPrinterName : defaultPrinter,
                        linnworksSource: order.linnworksSource ? order.linnworksSource : this.data.settings.linnworksSource,
                        linnworksSubsource: order.linnworksSubsource ? order.linnworksSubsource : this.data.settings.linnworksSubsource,
                        linnworksPdfUrl: order.linnworksPdfUrl ? order.linnworksPdfUrl : null,
                        stockRemoved: order.stockRemoved ? order.stockRemoved : false,
                        type: order.type ? order.type : '',
                        overrideItems: order.overrideItems ? order.overrideItems : [],
                        linnworksAddress: {
                            EmailAddress: order.linnworksAddress.EmailAddress,
                            Address1: order.linnworksAddress.Address1 ? order.linnworksAddress.Address1 : this.address.address1,
                            Address2: order.linnworksAddress.Address2 ? order.linnworksAddress.Address2 : this.address.address2,
                            Address3: order.linnworksAddress.Address3 ? order.linnworksAddress.Address3 : this.address.address3,
                            Town: order.linnworksAddress.Town ? order.linnworksAddress.Town : this.address.city,
                            Region: order.linnworksAddress.Region ? order.linnworksAddress.Region : this.address.region,
                            Continent: order.linnworksAddress.Continent ? order.linnworksAddress.Continent : '',
                            PostCode: order.linnworksAddress.PostCode ? order.linnworksAddress.PostCode : this.address.postCode,
                            Country: order.linnworksAddress.Country ? order.linnworksAddress.Country : selectedCountry.CountryName,
                            FullName: order.linnworksAddress.FullName ? order.linnworksAddress.FullName : '.',
                            Company: order.linnworksAddress.Company ? order.linnworksAddress.Company : this.address.company,
                            PhoneNumber: order.linnworksAddress.PhoneNumber ? order.linnworksAddress.PhoneNumber : this.data.settings.linnworksDefaultShippingPhone,
                            CountryId: order.linnworksAddress.CountryId ? order.linnworksAddress.CountryId : selectedCountry.CountryId,
                        },
                    }

                    const { stockRemoval } = resp.data
                    this.linnworksStockRemoval = {
                        status: stockRemoval.status,
                        errors: stockRemoval.errors,
                        stockRemoved: stockRemoval.stockRemoved,
                        stockRemovalReverted: stockRemoval.stockRemovalReverted,
                        totalItemCount: stockRemoval.totalItemCount,
                        successfulItemCount: stockRemoval.successfulItemCount,
                        successfulRevertedCount: stockRemoval.successfulRevertedCount,
                    }

                    this.shouldPrintInvoice = this.data.shouldPrintInvoice
                    if (this.data.order.linnworksStatus === 1) {
                        setTimeout(this.loadData, 5000)
                    }
                    if (this.data.order.linnworksStatus === 2 && this.forceDownload) {
                        this.downloadPdf()
                        this.forceDownload = false
                    }
                })
                .catch(() => {
                    this.loading = false
                })
        },
        parseCountryFromAddress() {
            const countryCode = this.address.country
            const returnCountry = { CountryId: '', CountryName: '', CountryCode: '' }
            for (const country of this.data.countries) {
                if (country.CountryCode.toUpperCase().trim() === countryCode.toUpperCase().trim()) {
                    returnCountry.CountryId = country.CountryId
                    returnCountry.CountryName = country.CountryName
                    returnCountry.CountryCode = country.CountryCode
                    break
                }
            }
            return returnCountry
        },
        refreshData() {
            this.loading = true
            Axios({
                url: `${process.env.VUE_APP_API_HTTP_ROOT}linnworks/refresh-data`,
                data: {},
                method: 'POST',
            })
                .then(() => {
                    this.loading = false
                    this.loadData()
                })
                .catch(() => {
                    this.loading = false
                })
        },
        downloadPdf() {
            if (!this.localOrder.linnworksPdfUrl) {
                return
            }
            const link = document.createElement('a')
            link.href = this.localOrder.linnworksPdfUrl
            link.download = 'invoice.pdf'
            link.target = '_blank'
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
        },
        printFakeOrder() {
            if (this.shouldPrintInvoice) {
                this.invoiceProductListModalVisible = true
            } else {
                this.sync(true)
            }
        },
        canRemoveStock() {
            return Access.checkAccess('linnworks_integration.remove_stock', Access.LEVEL_READ) && this.hasItemsToRemove() && !this.isPending()
        },
        canRevertStock() {
            return Access.checkAccess('linnworks_integration.revert_removed_stock', Access.LEVEL_READ) && this.hasItemsToRevert() && !this.isPending()
        },
        hasItemsToRevert() {
            return this.linnworksStockRemoval.successfulItemCount > this.linnworksStockRemoval.stockRemovalReverted
        },
        hasItemsToRemove() {
            return this.linnworksStockRemoval.successfulItemCount < this.linnworksStockRemoval.totalItemCount || this.linnworksStockRemoval.stockRemovalReverted > 0 || this.linnworksStockRemoval.successfulItemCount === 0
        },
        isPending() {
            return this.linnworksStockRemoval.status === SyncStatuses.PENDING
        },
        isValidPrinter(printerName) {
            if (!printerName) {
                return false
            }

            const validPrinters = this.data.printers.filter(printer => printer.PrinterName === printerName)
            return validPrinters.length > 0
        },
    },
}
</script>
