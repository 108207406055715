<template>
    <div class="dynamic-table-picker">
        <XcDropdownButton
            v-if="currentLayout"
            :disabled="loading"
            :loading="loading"
            :buttons="dropdownButtons"
            :primary-text="currentLayout.name"
            :force-display-dropdown="true"
            :primary-action="openConfigurator"
        />

        <dynamic-table-config
            v-if="!loading"
            :visible="configModalOpen"
            :available-fields="fields"
            :table-key="tableKey"
            :initial-layout-id="currentLayoutId"
            :loaded-layouts="layouts"
            @close="closeConfigModal"
            @reload="loadLayouts"
        />
    </div>
</template>

<style>
.dynamic-table-picker {
    display: inline-block;
}
</style>
<script>

import Axios from 'axios'
import XcDropdownButton from '@/components/Misc/XcDropdownButton.vue'
import DynamicTableConfig from './DynamicTableConfig.vue'
import { DynamicTableLocalStorageKeyBase } from '../../../constants'
import store from '@/store'

export default {
    components: {
        DynamicTableConfig,
        XcDropdownButton,
    },
    props: {
        defaultFields: {
            type: Array,
            default: () => [],
        },
        tableKey: {
            type: String,
            required: true,
        },
        currentLayoutId: {
            type: Number,
            default: 0,
        },
    },
    data() {
        return {
            configModalOpen: false,
            fields: this.defaultFields,
            layouts: [],
            loading: true,
            currentLayout: {
                id: 0,
                name: 'Default',
                isDefault: false,
                isCustom: false,
            },
            activeProject: store.getters.activeProject,
        }
    },
    computed: {
        dropdownButtons() {
            return this.layouts.map(layout => ({
                title: layout.name,
                action: this.switchLayout.bind(this, layout),
            }))
        },
    },
    mounted() {
        this.loadLayouts()
    },
    methods: {
        loadLayouts() {
            this.loading = true
            Axios({
                url: `${process.env.VUE_APP_API_HTTP_ROOT}table-layouts/list`,
                data: {
                    table_key: this.tableKey,
                },
                method: 'POST',
            })
                .then(data => {
                    this.fields = this.defaultFields.concat(data.data.fieldsForTable)
                    const defaultLayout = [this.getDefaultLayout()]
                    this.layouts = defaultLayout.concat(data.data.layouts)
                })
                .finally(() => {
                    this.switchLayout(this.loadInitialLayout())
                    this.loading = false
                })
        },
        getLocalStorageKey() {
            return `${DynamicTableLocalStorageKeyBase}-${this.tableKey}-${this.activeProject.id}`
        },
        loadInitialLayout() {
            // firstly try to find layout in local storage
            const localStorageLayoutId = localStorage.getItem(this.getLocalStorageKey())
            if (localStorageLayoutId) {
                for (const tmpLayout of this.layouts) {
                    if (parseInt(tmpLayout.id) === parseInt(localStorageLayoutId)) {
                        return tmpLayout
                    }
                }
            }
            // if not found, try to get default one loaded
            for (const tmpLayout of this.layouts) {
                if (tmpLayout.isDefault) {
                    return tmpLayout
                }
            }

            // if there is no default one, but some from BE, load first one of those
            for (const tmpLayout of this.layouts) {
                if (tmpLayout.isCustom) {
                    return tmpLayout
                }
            }

            // if all else fails, load the default layout
            return this.layouts[0]
        },
        closeConfigModal() {
            this.configModalOpen = false
            this.loadLayouts()
        },
        switchLayout(layout) {
            if (!layout) {
                return
            }
            this.currentLayout = layout
            localStorage.setItem(this.getLocalStorageKey(), layout.id)
            this.$emit('layout-changed', layout)
        },
        openConfigurator() {
            this.configModalOpen = true
            this.$root.$emit('bv::show::modal', 'dynamicTableConfig')
        },
        getDefaultLayout() {
            const fields = this.fields
                .filter(field => field.enabledByDefault)
                .map(field => {
                    const newField = JSON.parse(JSON.stringify(field))
                    newField.checked = true
                    newField.showSettings = false
                    newField.label = newField.name
                    newField.columnWidth = 200
                    newField.columnWidthDynamic = true

                    return newField
                })

            return {
                id: 0,
                name: 'Default',
                layout: fields,
            }
        },
        removeHiddenFields(layout) {
            layout.layout = layout.layout.filter(field => field.checked)

            return layout
        }
    },
}
</script>
